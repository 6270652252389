import React from "react";
import PropTypes from "prop-types";
import axios from "axios";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import Check from "@material-ui/icons/Check";
import Face from "@material-ui/icons/Face";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import registerPageStyle from "assets/jss/material-dashboard-react/views/registerPageStyle.jsx";
import Modal from '@material-ui/core/Modal';

import CircularProgress from '@material-ui/core/CircularProgress';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Urls from '../../Utils/Urls';
import CustomeModal from '../../Utils/Modal';

const { REACT_APP_SERVER_URL } = process.env;

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      errors: {},
      isLoading:false,
      modelOpen:false
    };
    this.modelOpen = true;
    this.username = '';
  }

  pushToLoginPage = () => {
    const { history } = this.props;
    return history.push("/login");
  }

  register = async e => {
    e.preventDefault();
    this.setState({isLoading:true});
    const { history } = this.props;

    const fields = ["name", "username", "password"];
    const formElements = e.target.elements;
    this.username = formElements.namedItem('name').value;

    const formValues = {
      preferred_username: formElements.namedItem('name').value,
      email: formElements.namedItem('username').value,
      pass: formElements.namedItem('password').value
    }
    
    // fields
    //   .map(field => ({
    //     [field]: formElements.namedItem(field).value
    //   }))
    //   .reduce((current, next) => ({ ...current, ...next }));
    

    let registerRequest;
    try {

      var config = {
        method: 'post',
        url: `${Urls.baseUrl}/create-user`,
        data : JSON.stringify(formValues)
      };
      
      registerRequest = await axios(
        config
      );

    } catch ({ response }) {
      registerRequest = response;
      toast.error(`Check Your Internat`);
      this.setState({isLoading:false});
    }

    console.log(registerRequest);

    if (registerRequest.data.body === "Success") {
      toast.success(`Registered Successfully`);
      this.setState({isLoading:false});
      this.setState({modelOpen:true});
      console.log(this.state.modelOpen);
    }else{
      toast.error(registerRequest.data.value.message);
      this.setState({isLoading:false});
      var err = {
        username: registerRequest ? true : false,
        password: registerRequest ? true : false
      }
      this.setState({
        errors:err
      });
    }
  };

  handleModel = val => {
    this.setState({modelOpen:val});
  }

  handleToggle = value => {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked
    });
  };
  render() {
    const { classes } = this.props;
    const { errors } = this.state;
    return (
      <div className={classes.container}>

        <GridContainer justify="center">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover>
        </ToastContainer>
          <GridItem xs={12} sm={6} md={4}>
          <h4 className={classes.textCenter} style={{ marginTop: 0 }}>
            Register in to ICSE Admin Portal{" "}
          </h4>
            <form onSubmit={this.register}>
              <Card className={classes[this.state.cardAnimaton]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="primary"
                >
                  <h4 className={classes.cardTitle}>Register</h4>
                  <div className={classes.socialLine}>
                   {
                      this.state.isLoading ? <CircularProgress style={{color:"white"}} /> : null
                    }
                    {/* {[
                      "fa fa-facebook-square",
                      "fa fa-twitter",
                      "fa fa-google-plus"
                    ].map((prop, key) => {
                      return (
                        <Button
                          color="transparent"
                          justIcon
                          key={key}
                          className={classes.customButtonClass}
                        >
                          <i className={prop} />
                        </Button>
                      );
                    })} */}
                  </div>
                </CardHeader>
                <CardBody>
                  <p className={classes.cardDescription}>Or Be Classical</p>
                  <CustomInput
                    labelText="Username..."
                    id="name"
                    formControlProps={{
                      fullWidth: true,
                      className: classes.formControlClassName
                    }}
                    inputProps={{
                      required: true,
                      name: "name",
                      endAdornment: (
                        <InputAdornment position="end">
                          <Face className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      )
                    }}
                  />
                  <CustomInput
                    labelText="Email..."
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                      className: classes.formControlClassName
                    }}
                    error={errors.username}
                    inputProps={{
                      required: true,
                      type: "email",
                      name: "username",
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      )
                    }}
                  />
                  <CustomInput
                    labelText="Password..."
                    id="password"
                    formControlProps={{
                      fullWidth: true,
                      className: classes.formControlClassName
                    }}
                    error={errors.password}
                    inputProps={{
                      required: true,
                      name: "password",
                      type: "password",
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      )
                    }}
                  />
                  <FormControlLabel
                    classes={{
                      root:
                        classes.checkboxLabelControl +
                        " " +
                        classes.checkboxLabelControlClassName,
                      label: classes.checkboxLabel
                    }}
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle(1)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        required
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    label={
                      <span>
                        I agree with the <a href="#pablo">Privacy Policy</a>.
                      </span>
                    }
                  />
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <Button type="submit" color="primary" simple size="lg" block disabled={this.state.isLoading}>
                    Let's Go
                  </Button>
                </CardFooter>
              </Card>
            </form>
            {
            this.state.modelOpen? 
            <CustomeModal
              modelOpen={this.state.modelOpen}
              handleModel={this.handleModel}
              username={this.username}
              pushLogin = {this.pushToLoginPage}
              >
              
            </CustomeModal>
             :null
          } 
          </GridItem>

          
        </GridContainer>
      </div>
    );
  }
}

RegisterPage.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object
};

export default withStyles(registerPageStyle)(RegisterPage);
