import React, { useState, useEffect } from 'react'
import { Modal, IconButton, Backdrop, Grid, Slide, Button } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import axios from 'axios';
import OtpTextField from '../Utils/OtpTextField';
import Urls from '../Utils/Urls';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        display: "flex",
        minWidth: "500px",
        width: "25%",
        border: '2px solid #9931B1',
        flexDirection: "row",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    select: {
        minWidth: 140,
        padding: 10,
    }
}));
const Model = (props) => {
    const classes = useStyles();
    const [val, setVal] = useState();
    const [obj, setObj] = useState();
    const [user, setUser] = useState();
    const [isConfermed, SetIsConfermed] = useState(false);
    const [name, setName] = useState('Report Not Selected Yet');
    const { modelOpen, handleModel, username, pushLogin } = props
    const [ valOpen, setValOpen ] = useState(false);
    let [, setState] = useState();
    console.log(props);

    const cancel = () => {
        setValOpen(true);
        handleModel(false);
        toast.warning(`Your Registration Will Cancelled`);
    }

    const handleValue = value => {
        console.log(value);
        setUser(value);
    }

    const verify = async () => {
        SetIsConfermed(true);
        let registerRequest;
        var data = {
            username: username,
            code:user
        }
        try {
            var config = {
                method: 'post',
                url: `${Urls.baseUrl}/confirm-user`,
                data : JSON.stringify(data)
            };
            
            registerRequest = await axios(
                config
            );

        } catch ({ response }) {
            registerRequest = response;
            console.log(response);
            toast.error(`Check Your Internet`);
        }

        if(registerRequest.status === 200 && registerRequest.data.user_confirmation && registerRequest.data.user_disabled ){
            toast.info(`Connect To Your Admin For Enable Your Credentials`);
            setTimeout(function(){ setValOpen(true);
                handleModel(false);
                pushLogin();}, 2000);

            
        }else{
            console.log(registerRequest);
            var value = registerRequest.data.errorMessage ? registerRequest.data.errorMessage : "Failed To Confirm OTP" 
            toast.error(value);
            SetIsConfermed(false);
            console.log("Invalid OTP")
        }
    }

    useEffect(() => {
        console.log("**")
        setValOpen(true);
    }, []);

    const View =
        <Container  style={{ fontFamily: 'uiRounded', alignItems: 'center'}} >
            <ToastContainer
            position="top-right"
            autoClose={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            />
            <Grid container >
            <Grid item xs={12}>
            <Typography display="block" style={{ fontFamily: 'uiRounded', textAlign: "center", flex: 1}}>
                Enter OTP
            </Typography>
            </Grid>
            <Typography display="block" style={{ fontFamily: 'uiRounded', textAlign: "center", flex: 1, color: "#9C27B0"}}>
                Check Your Mail For the OTP
            </Typography>
                <Grid item xs={12}  style={{ marginLeft:'5px',  marginTop: '15px'}}>
                    <OtpTextField
                    handleValue={handleValue}>
                    </OtpTextField>
                </Grid>
                <Grid container style={{  textAlign: "center", flex: 1}}  >
                    <Grid item xs={4} style={{ marginTop: '5px' }}>
                        <IconButton variant="contained" onClick={verify} disabled={isConfermed}>
                            <CheckCircleIcon style={{ color: "#4CAF50", fontSize:'35px'}} />
                        </IconButton>
                    </Grid>
                    <Grid item xs={4} style={{ marginTop: '5px' }} disabled={isConfermed}>
                        <IconButton variant="contained"  onClick={cancel} >
                            <CancelIcon style={{ color: "#E91E63",fontSize:'35px' }}/>
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </Container>


    return (
        <Modal
            className={classes.modal}
            open={valOpen}
            onClose={ handleModel}
            closeAfterTransition
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>
            <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                <div className={`${classes.paper} modalContainer`} style={{ height: "40%", overflow: "auto", minWidth: '300px', alignItems: 'center' }}
                >
                    {View}
                </div>
            </Slide>
        </Modal>
    )
}

export default Model;