import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Hidden from "@material-ui/core/Hidden";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import axios from "axios";
import iconsStyle from "assets/jss/material-dashboard-react/views/iconsStyle.jsx";
import Button from '@material-ui/core/Button';
import "../../Utils/custom.css"
import "../../Utils/responsive.css"
import "bootstrap/dist/css/bootstrap.css";

function Icons(props) {
  //const classes = useStyles();
  console.log(props);

  const { classes } = props;
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [code0, setCode0] = useState();
  const [code1, setCode1] = useState();
  const [code2, setCode2] = useState();
  const [code3, setCode3] = useState();
  const [code4, setCode4] = useState();

  const [UniqueCode0, setUniqueCode0] = useState();
  const [UniqueCode1, setUniqueCode1] = useState();
  const [UniqueCode2, setUniqueCode2] = useState();
  const [UniqueCode3, setUniqueCode3] = useState();
  const [UniqueCode4, setUniqueCode4] = useState();

  const [IndexCode0, setIndexCode0] = useState();
  const [IndexCode1, setIndexCode1] = useState();
  const [IndexCode2, setIndexCode2] = useState();
  const [IndexCode3, setIndexCode3] = useState();
  const [IndexCode4, setIndexCode4] = useState();
  const [IndexCode5, setIndexCode5] = useState();
  const [IndexCode6, setIndexCode6] = useState();
  const [IndexCode7, setIndexCode7] = useState();
  const [IndexCode8, setIndexCode8] = useState();
  const [IndexCode9, setIndexCode9] = useState();

  const [SubjectCode0, setSubjectCode0] = useState();
  const [SubjectCode1, setSubjectCode1] = useState();

  const [PageNo, setPageNo] = useState();

  const [YearExamination, setYearExamination] = useState();
  const [YearExamination1, setYearExamination1] = useState();
  const [YearExamination2, setYearExamination2] = useState();
  const [YearExamination3, setYearExamination3] = useState();

  const [InitialsOFExaminar, setInitialsOFExaminar] = useState();
  // const [InitialsOFExaminar, setInitialsOFExaminar] = useState();
  const [date, setDate] = useState();
  const [date1, setDate1] = useState();
  const [date2, setDate2] = useState();
  const [date3, setDate3] = useState();
  const [date4, setDate4] = useState();
  const [date5, setDate5] = useState();
  const [date6, setDate6] = useState();
  const [date7, setDate7] = useState();


  const [time, setTime] = useState();

  const [name, setName] = useState();

  const [subject, setSubject] = useState();

  const [YearOfExaminationFfull, setYearOfExaminationFfull] = useState();

  const [UniqueIdFull, SetUniqueIdFull] = useState()

  const [FullIndexNo, setFullIndexNo] = useState()

  const [signature, setSignature] = useState();

  const [QueOne1, setQueOne1 ] = useState();
  const [QueOne2, setQueOne2 ] = useState();
  const [QueOne3, setQueOne3 ] = useState();
  const [QueTwo1, setQueTwo1 ] = useState();
  const [QueTwo2, setQueTwo2 ] = useState();
  const [QueTwo3, setQueTwo3 ] = useState();
  const [QueThree1, setQueThree1 ] = useState();
  const [QueThree2, setQueThree2 ] = useState();
  const [QueThree3, setQueThree3 ] = useState();
  const [QueFour1, setQueFour1 ] = useState();
  const [QueFour2, setQueFour2 ] = useState();
  const [QueFour3, setQueFour3 ] = useState();
  const [QueFive1, setQueFive1 ] = useState();
  const [QueFive2, setQueFive2 ] = useState();
  const [QueFive3, setQueFive3 ] = useState();
  const [QueSix1, setQueSix1 ] = useState();
  const [QueSix2, setQueSix2 ] = useState();
  const [QueSix3, setQueSix3 ] = useState();
  const [QueSeven1, setQueSeven1 ] = useState();
  const [QueSeven2, setQueSeven2 ] = useState();
  const [QueSeven3, setQueSeven3 ] = useState();
  const [QueEight1, setQueEight1 ] = useState();
  const [QueEight2, setQueEight2 ] = useState();
  const [QueEight3, setQueEight3 ] = useState();
  const [QueNine1, setQueNine1 ] = useState();
  const [QueNine2, setQueNine2 ] = useState();
  const [QueNine3, setQueNine3 ] = useState();
  const [QueTen1, setQueTen1 ] = useState();
  const [QueTen2, setQueTen2 ] = useState();
  const [QueTen3, setQueTen3 ] = useState();
  const [QueEle1, setQueEle1 ] = useState();
  const [QueEle2, setQueEle2 ] = useState();
  const [QueEle3, setQueEle3 ] = useState();
  const [QueTwle1, setQueTwle1 ] = useState();
  const [QueTwle2, setQueTwle2 ] = useState();
  const [QueTwle3, setQueTwle3 ] = useState();
  const [QueThirt1, setQueThirt1 ] = useState();
  const [QueThirt2, setQueThirt2 ] = useState();
  const [QueThirt3, setQueThirt3 ] = useState();
  const [QueForteen1, setQueForteen1 ] = useState();
  const [QueForteen2, setQueForteen2 ] = useState();
  const [QueForteen3, setQueForteen3 ] = useState();
  const [QueFifteen1, setQueFifteen1 ] = useState();
  const [QueFifteen2, setQueFifteen2 ] = useState();
  const [QueFifteen3, setQueFifteen3 ] = useState();
  const [QueSixteen1, setQueSixteen1 ] = useState();
  const [QueSixteen2, setQueSixteen2 ] = useState();
  const [QueSixteen3, setQueSixteen3 ] = useState();
  const [QueSeventeen1, setQueSeventeen1 ] = useState();
  const [QueSeventeen2, setQueSeventeen2 ] = useState();
  const [QueSeventeen3, setQueSeventeen3 ] = useState();
  const [QueEighteen1, setQueEighteen1 ] = useState();
  const [QueEighteen2, setQueEighteen2 ] = useState();
  const [QueEighteen3, setQueEighteen3 ] = useState();
  const [QueNinteen1, setQueNinteen1 ] = useState();
  const [QueNinteen2, setQueNinteen2 ] = useState();
  const [QueNinteen3, setQueNinteen3 ] = useState();
  const [QueTwenty1, setQueTwenty1 ] = useState();
  const [QueTwenty2, setQueTwenty2 ] = useState();
  const [QueTwenty3, setQueTwenty3 ] = useState();
  const [QueTwentyOne1, setQueTwentyOne1 ] = useState();
  const [QueTwentyOne2, setQueTwentyOne2 ] = useState();
  const [QueTwentyOne3, setQueTwentyOne3 ] = useState();
  const [QueTwentyTwo1, setQueTwentyTwo1 ] = useState();
  const [QueTwentyTwo2, setQueTwentyTwo2 ] = useState();
  const [QueTwentyTwo3, setQueTwentyTwo3 ] = useState();

  const [Que1One1, setQue1One1 ] = useState();
  const [Que1One2, setQue1One2 ] = useState();
  const [Que1One3, setQue1One3 ] = useState();
  const [Que1Two1, setQue1Two1 ] = useState();
  const [Que1Two2, setQue1Two2 ] = useState();
  const [Que1Two3, setQue1Two3 ] = useState();
  const [Que1Three1, setQue1Three1 ] = useState();
  const [Que1Three2, setQue1Three2 ] = useState();
  const [Que1Three3, setQue1Three3 ] = useState();
  const [Que1Four1, setQue1Four1 ] = useState();
  const [Que1Four2, setQue1Four2 ] = useState();
  const [Que1Four3, setQue1Four3 ] = useState();
  const [Que1Five1, setQue1Five1 ] = useState();
  const [Que1Five2, setQue1Five2 ] = useState();
  const [Que1Five3, setQue1Five3 ] = useState();
  const [Que1Six1, setQue1Six1 ] = useState();
  const [Que1Six2, setQue1Six2 ] = useState();
  const [Que1Six3, setQue1Six3 ] = useState();
  const [Que1Seven1, setQue1Seven1 ] = useState();
  const [Que1Seven2, setQue1Seven2 ] = useState();
  const [Que1Seven3, setQue1Seven3 ] = useState();
  const [Que1Eight1, setQue1Eight1 ] = useState();
  const [Que1Eight2, setQue1Eight2 ] = useState();
  const [Que1Eight3, setQue1Eight3 ] = useState();
  const [Que1Nine1, setQue1Nine1 ] = useState();
  const [Que1Nine2, setQue1Nine2 ] = useState();
  const [Que1Nine3, setQue1Nine3 ] = useState();
  const [Que1Ten1, setQue1Ten1 ] = useState();
  const [Que1Ten2, setQue1Ten2 ] = useState();
  const [Que1Ten3, setQue1Ten3 ] = useState();
  const [Que1Ele1, setQue1Ele1 ] = useState();
  const [Que1Ele2, setQue1Ele2 ] = useState();
  const [Que1Ele3, setQue1Ele3 ] = useState();
  const [Que1Twle1, setQue1Twle1 ] = useState();
  const [Que1Twle2, setQue1Twle2 ] = useState();
  const [Que1Twle3, setQue1Twle3 ] = useState();
  const [Que1Thirt1, setQue1Thirt1 ] = useState();
  const [Que1Thirt2, setQue1Thirt2 ] = useState();
  const [Que1Thirt3, setQue1Thirt3 ] = useState();
  const [Que1Forteen1, setQue1Forteen1 ] = useState();
  const [Que1Forteen2, setQue1Forteen2 ] = useState();
  const [Que1Forteen3, setQue1Forteen3 ] = useState();
  const [Que1Fifteen1, setQue1Fifteen1 ] = useState();
  const [Que1Fifteen2, setQue1Fifteen2 ] = useState();
  const [Que1Fifteen3, setQue1Fifteen3 ] = useState();
  const [Que1Sixteen1, setQue1Sixteen1 ] = useState();
  const [Que1Sixteen2, setQue1Sixteen2 ] = useState();
  const [Que1Sixteen3, setQue1Sixteen3 ] = useState();
  const [Que1Seventeen1, setQue1Seventeen1 ] = useState();
  const [Que1Seventeen2, setQue1Seventeen2 ] = useState();
  const [Que1Seventeen3, setQue1Seventeen3 ] = useState();
  const [Que1Eighteen1, setQue1Eighteen1 ] = useState();
  const [Que1Eighteen2, setQue1Eighteen2 ] = useState();
  const [Que1Eighteen3, setQue1Eighteen3 ] = useState();
  const [Que1Ninteen1, setQue1Ninteen1 ] = useState();
  const [Que1Ninteen2, setQue1Ninteen2 ] = useState();
  const [Que1Ninteen3, setQue1Ninteen3 ] = useState();
  const [Que1Twenty1, setQue1Twenty1 ] = useState();
  const [Que1Twenty2, setQue1Twenty2 ] = useState();
  const [Que1Twenty3, setQue1Twenty3 ] = useState();
  const [Que1TwentyOne1, setQue1TwentyOne1 ] = useState();
  const [Que1TwentyOne2, setQue1TwentyOne2 ] = useState();
  const [Que1TwentyOne3, setQue1TwentyOne3 ] = useState();
  const [Que1TwentyTwo1, setQue1TwentyTwo1 ] = useState();
  const [Que1TwentyTwo2, setQue1TwentyTwo2 ] = useState();
  const [Que1TwentyTwo3, setQue1TwentyTwo3 ] = useState();


  const UploadData = async () => {
    setButtonDisabled(true);
    const { history } = props;
    var sendData={
      formValues:{
        code:parseInt(code0+''+code1+''+code2+''+code3+''+code4),
        UniqueCode: parseInt(UniqueCode0+''+UniqueCode1+''+UniqueCode2+''+UniqueCode3+''+UniqueCode4),
        IndexCode: parseInt(IndexCode0+''+IndexCode1+''+IndexCode2+''+IndexCode3+''+IndexCode4+''+IndexCode5+''+IndexCode6+''+IndexCode7+''+IndexCode8+''+IndexCode9),
        SubjectCode: parseInt(SubjectCode0+''+SubjectCode1),
        PageNo: parseInt(PageNo),
        YearOfExamination: parseInt(YearExamination+''+YearExamination1+''+YearExamination2+''+YearExamination3),
        InitialsExaminar:  InitialsOFExaminar,
        DateVal: parseInt(date+''+date1+''+date2+''+date3+''+date4+''+date5+''+date6+''+date7),
        TimeVal: time,
        Subject: subject,
        Signature: signature
      },
      tableValues:[
        {
          one:QueOne1+''+QueOne2+'.'+QueOne3,
          two:Que1One1+''+Que1One2+'.'+Que1One3
        },
        {
          one:QueTwo1+''+QueTwo2+'.'+QueTwo3,
          two:Que1Two1+''+Que1Two2+'.'+Que1Two3
        },
        {
          one:QueThree1+''+QueThree2+'.'+QueThree3,
          two:Que1Three1+''+Que1Three2+'.'+Que1Three3
        },
        {
          one:QueFour1+''+QueFour2+'.'+QueFour3,
          two:Que1Four1+''+Que1Four2+'.'+Que1Four3
        },
        {
          one:QueFive1+''+QueFive2+'.'+QueFive3,
          two:Que1Five1+''+Que1Five2+'.'+Que1Five3
        },
        {
          one:QueSix1+''+QueSix2+'.'+QueSix3,
          two:Que1Six1+''+Que1Six2+'.'+Que1Six3
        },
        {
          one:QueSeven1+''+QueSeven2+'.'+QueSeven3,
          two:Que1Seven1+''+Que1Seven2+'.'+Que1Seven3
        },
        {
          one:QueEight1+''+QueEight2+'.'+QueEight3,
          two:Que1Eight1+''+Que1Eight2+'.'+Que1Eight3
        },
        {
          one:QueNine1+''+QueNine2+'.'+QueNine3,
          two:Que1Nine1+''+Que1Nine2+'.'+Que1Nine3
        },
        {
          one:QueTen1+''+QueTen2+'.'+QueTen3,
          two:Que1Ten1+''+Que1Ten2+'.'+Que1Ten3
        },
        {
          one:QueEle1+''+QueEle2+'.'+QueEle3,
          two:Que1Ele1+''+Que1Ele2+'.'+Que1Ele3
        },
        {
          one:QueTwle1+''+QueTwle2+'.'+QueTwle3,
          two:Que1Twle1+''+Que1Twle2+'.'+Que1Twle3
        },
        {
          one:QueThirt1+''+QueThirt2+'.'+QueThirt3,
          two:Que1Thirt1+''+Que1Thirt2+'.'+Que1Thirt3
        },
        {
          one:QueForteen1+''+QueForteen2+'.'+QueForteen3,
          two:Que1Forteen1+''+Que1Forteen2+'.'+Que1Forteen3
        },
        {
          one:QueFifteen1+''+QueFifteen2+'.'+QueFifteen3,
          two:Que1Fifteen1+''+Que1Fifteen2+'.'+Que1Fifteen3
        },
        {
          one:QueSixteen1+''+QueSixteen2+'.'+QueSixteen3,
          two:Que1Sixteen2+''+Que1Sixteen2+'.'+Que1Sixteen3
        },
        {
          one:QueSeventeen1+''+QueSeventeen2+'.'+QueSeventeen3,
          two:Que1Seventeen1+''+Que1Seventeen2+'.'+Que1Seventeen3
        },
        {
          one:QueEighteen1+''+QueEighteen2+'.'+QueEighteen3,
          two:Que1Eighteen1+''+Que1Eighteen2+'.'+Que1Eighteen2
        },
        {
          one:QueNinteen1+''+QueNinteen2+'.'+Que1Ninteen3,
          two:Que1Ninteen1+''+Que1Ninteen2+'.'+Que1Ninteen3        },
        {
          one:QueTwenty1+''+QueTwenty2+'.'+QueTwenty3,
          two:Que1Twenty1+''+Que1Twenty2+'.'+Que1Twenty3
        },
        {
          one:QueTwentyTwo1+''+QueTwentyTwo2+'.'+QueTwentyTwo3,
          two:Que1TwentyTwo1+''+Que1TwentyTwo2+'.'+Que1TwentyTwo3
        }
      ]
    }

    var finalValue = {
      type:"post",
      data: JSON.stringify(sendData),
      sheet_name: props.formValues.url.split('amazonaws.com/')[1],
      inserted_by: localStorage.getItem('username') 
    }

    var config = {
      method: 'post',
      url: 'https://icse-demo.digiclave.in/upload-sheet-data',
      data : JSON.stringify(finalValue)
    };

    var result = await axios(config);

    console.log(result);
    setButtonDisabled(false);

    window.location.replace("https://omcsnextgen.digiclave.in/admin/table");
  }


  const makeData = () => {
    var code = props.formValues.ocr.formData['Please enter the code shown on the Tablet Device'];
    var UniqueCode = props.formValues.ocr.formData['Unique ID'];
    var IndexCode = props.formValues.ocr.formData['Index No'];
    var SubjectCode = props.formValues.ocr.formData['Subject Code'];
    var PageNo = props.formValues.ocr.formData['Paper No'];
    var YearOfExamination = props.formValues.ocr.formData['Year of Examination'];
    var InitialsExaminar = props.formValues.ocr.formData['Initials of the Supervising Examiner'];
    var DateVal = props.formValues.ocr.formData['Date'];
    var TimeVal = props.formValues.ocr.formData['Time'];
    var Subject = props.formValues.ocr.formData['Subject'];
    var Signature = props.formValues.ocr.formData['Signature of the Candidate'];
    
    var tableData = props.formValues.ocr.tableData;

    console.log(Object.values(tableData[0][3])[0])

    console.log(Object.values(tableData[0][3])[4]?Object.values(tableData[0][3])[4]:"No four")
    console.log(Object.values(tableData[0][3]));
    console.log(Object.values(tableData[0][3])[0]); 
    console.log(Object.values(tableData[0][3])[1]);
    console.log(Object.values(tableData[0][3])[1]);

   // console.log(code[0], code[2], code[4], code[6], code[8])
    console.log(UniqueCode)


    setCode0(parseInt(code[0]) ? parseInt(code[0]) : '')
    setCode1(parseInt(code[2]) ? parseInt(code[2]) : '')
    setCode2(parseInt(code[4]) ? parseInt(code[4]) : '')
    setCode3(parseInt(code[6]) ? parseInt(code[6]) : '')
    setCode4(parseInt(code[8]) ? parseInt(code[8]) : '')

    setUniqueCode0(Number.isNaN(parseInt(UniqueCode[0])) !== true ? parseInt(UniqueCode[0]) : '');
    setUniqueCode1(Number.isNaN(parseInt(UniqueCode[2])) !== true ? parseInt(UniqueCode[2]) : '')
    setUniqueCode2(Number.isNaN(parseInt(UniqueCode[4])) !== true ? parseInt(UniqueCode[4]) : '')
    setUniqueCode3(Number.isNaN(parseInt(UniqueCode[6])) !== true ? parseInt(UniqueCode[6]) : '')
    setUniqueCode4(Number.isNaN(parseInt(UniqueCode[8])) !== true ? parseInt(UniqueCode[8]) : '')

    setIndexCode0(Number.isNaN(parseInt(IndexCode[0])) !== true ? parseInt(IndexCode[0]) : '');
    setIndexCode1(Number.isNaN(parseInt(IndexCode[2])) !== true ? parseInt(IndexCode[2]) : '')
    setIndexCode2(Number.isNaN(parseInt(IndexCode[4])) !== true ? parseInt(IndexCode[4]) : '')
    setIndexCode3(Number.isNaN(parseInt(IndexCode[6])) !== true ? parseInt(IndexCode[6]) : '')
    setIndexCode4(Number.isNaN(parseInt(IndexCode[8])) !== true ? parseInt(IndexCode[8]) : '')
    setIndexCode5(Number.isNaN(parseInt(IndexCode[10])) !== true ? parseInt(IndexCode[10]) : '');
    setIndexCode6(Number.isNaN(parseInt(IndexCode[12])) !== true ? parseInt(IndexCode[12]) : '')
    setIndexCode7(Number.isNaN(parseInt(IndexCode[16])) !== true ? parseInt(IndexCode[16]) : '')
    setIndexCode8(Number.isNaN(parseInt(IndexCode[18])) !== true ? parseInt(IndexCode[18]) : '')
    setIndexCode9(Number.isNaN(parseInt(IndexCode[20])) !== true ? parseInt(IndexCode[20]) : '')

    setSubjectCode0(Number.isNaN(parseInt(SubjectCode[0])) !== true ? parseInt(SubjectCode[0]) : '')
    setSubjectCode1(Number.isNaN(parseInt(SubjectCode[2])) !== true ? parseInt(SubjectCode[2]) : '')

    setPageNo(Number.isNaN(parseInt(PageNo[0])) !== true ? parseInt(PageNo[0]) : '')

    setYearExamination(Number.isNaN(parseInt(YearOfExamination[0])) !== true ? parseInt(YearOfExamination[0]) : '')
    setYearExamination1(Number.isNaN(parseInt(YearOfExamination[2])) !== true ? parseInt(YearOfExamination[2]) : '')
    setYearExamination2(Number.isNaN(parseInt(YearOfExamination[4])) !== true ? parseInt(YearOfExamination[4]) : '')
    setYearExamination3(Number.isNaN(parseInt(YearOfExamination[6])) !== true ? parseInt(YearOfExamination[6]) : '')

    setInitialsOFExaminar(InitialsExaminar);
    setDate(Number.isNaN(parseInt(DateVal[0])) !== true ? parseInt(DateVal[0]) : '')
    setDate1(Number.isNaN(parseInt(DateVal[2])) !== true ? parseInt(DateVal[2]) : '')
    setDate2(Number.isNaN(parseInt(DateVal[4])) !== true ? parseInt(DateVal[4]) : '')
    setDate3(Number.isNaN(parseInt(DateVal[6])) !== true ? parseInt(DateVal[6]) : '')
    setDate4(Number.isNaN(parseInt(DateVal[8])) !== true ? parseInt(DateVal[8]) : '')
    setDate5(Number.isNaN(parseInt(DateVal[10])) !== true ? parseInt(DateVal[10]) : '')
    setDate6(Number.isNaN(parseInt(DateVal[12])) !== true ? parseInt(DateVal[12]) : '')
    setDate7(Number.isNaN(parseInt(DateVal[14])) !== true ? parseInt(DateVal[14]) : '')

    setTime(TimeVal);

    setSubject(Subject);

    setYearOfExaminationFfull(YearOfExamination)

    SetUniqueIdFull(UniqueCode)

    setFullIndexNo(IndexCode)

    setSignature(Signature)

    setQueOne1(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ?   Object.values(tableData[0][3])[1][0] : Object.values(tableData[0][3])[0][0])
    setQueOne2(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][3])[1][2] :  Object.values(tableData[0][3])[0][2])
    setQueOne3(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][3])[1][6]+''+Object.values(tableData[0][3])[1][7] : Object.values(tableData[0][3])[0][6]+''+Object.values(tableData[0][3])[0][7]) 
    setQueTwo1(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][4])[1][0] : Object.values(tableData[0][4])[0][0]) 
    setQueTwo2(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][4])[1][2] : Object.values(tableData[0][4])[0][2]) 
    setQueTwo3(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][4])[1][6]+''+Object.values(tableData[0][4])[1][7] : Object.values(tableData[0][4])[0][6]+''+Object.values(tableData[0][4])[0][7]) 
    setQueThree1(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][5])[1][0] : Object.values(tableData[0][5])[0][0]) 
    setQueThree2(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][5])[1][2] : Object.values(tableData[0][5])[0][2]) 
    setQueThree3(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][5])[1][6]+''+Object.values(tableData[0][5])[1][7] : Object.values(tableData[0][5])[0][6]+''+Object.values(tableData[0][5])[0][7]) 
    setQueFour1(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][6]+[7])[1][0] : Object.values(tableData[0][6])[0][0]) 
    setQueFour2(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][6]+[7])[1][2] : Object.values(tableData[0][6])[0][2]) 
    setQueFour3(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][6]+[7])[1][6]+''+Object.values(tableData[0][6])[1][7]: Object.values(tableData[0][6]+[7])[0][6]+''+Object.values(tableData[0][6])[0][7]) 
    setQueFive1(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][7])[1][0] : Object.values(tableData[0][7])[0][0]) 
    setQueFive2(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][7])[1][2] : Object.values(tableData[0][7])[0][2]) 
    setQueFive3(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][7])[1][6]+''+Object.values(tableData[0][7])[1][7] : Object.values(tableData[0][7])[0][6]+''+Object.values(tableData[0][7])[0][7]) 
    setQueSix1(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][8])[1][0] : Object.values(tableData[0][8])[0][0]) 
    setQueSix2(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][8])[1][2]: Object.values(tableData[0][8])[0][2]) 
    setQueSix3(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][8])[1][6]+''+Object.values(tableData[0][8])[1][7] : Object.values(tableData[0][8])[0][6]+''+Object.values(tableData[0][8])[0][7]) 
    setQueSeven1(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][9])[1][0] : Object.values(tableData[0][9])[0][0]) 
    setQueSeven2(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][9])[1][2] : Object.values(tableData[0][9])[0][2]) 
    setQueSeven3(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][9])[1][6]+''+Object.values(tableData[0][9])[1][7] : Object.values(tableData[0][9])[0][6]+''+Object.values(tableData[0][9])[0][7]) 
    setQueEight1(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][10])[1][0] : Object.values(tableData[0][10])[0][0]) 
    setQueEight2(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][10])[1][2] : Object.values(tableData[0][10])[0][6]+''+Object.values(tableData[0][10])[0][7]) 
    setQueEight3(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][10])[1][6]+''+Object.values(tableData[0][10])[1][7] : Object.values(tableData[0][10])[0][6]+''+Object.values(tableData[0][10])[0][7]) 
    setQueNine1(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][11])[1][0] : Object.values(tableData[0][11])[0][0]) 
    setQueNine2(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][11])[1][2] : Object.values(tableData[0][11])[0][2]) 
    setQueNine3(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][11])[1][6]+''+Object.values(tableData[0][11])[1][7] : Object.values(tableData[0][11])[0][6]+''+Object.values(tableData[0][11])[0][7]) 
    setQueTen1(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][12])[1][0] : Object.values(tableData[0][12])[0][0]) 
    setQueTen2(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][12])[1][2] : Object.values(tableData[0][12])[0][2]) 
    setQueTen3(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][12])[1][6]+''+Object.values(tableData[0][12])[1][7] : Object.values(tableData[0][12])[0][6]+''+Object.values(tableData[0][12])[0][7]) 
    setQueEle1(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][13])[1][0] : Object.values(tableData[0][13])[0][0]) 
    setQueEle2(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][13])[1][2] : Object.values(tableData[0][13])[0][2]) 
    setQueEle3(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][13])[1][6]+''+Object.values(tableData[0][13])[1][7] : Object.values(tableData[0][13])[0][6]+''+Object.values(tableData[0][13])[0][7]) 
    setQueTwle1(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][14])[1][0] : Object.values(tableData[0][14])[0][0]) 
    setQueTwle2(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][14])[0][2] : Object.values(tableData[0][14])[0][2] ) 
    setQueTwle3(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][14])[1][6]+''+Object.values(tableData[0][14])[1][7] : Object.values(tableData[0][14])[0][6]+''+Object.values(tableData[0][14])[0][7]) 
    setQueThirt1(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][15])[1][0] : Object.values(tableData[0][15])[0][0]) 
    setQueThirt2(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][15])[1][2] : Object.values(tableData[0][15])[0][2]) 
    setQueThirt3(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][15])[1][6]+''+Object.values(tableData[0][15])[1][7] : Object.values(tableData[0][15])[0][6]+''+Object.values(tableData[0][15])[0][7]) 
    setQueForteen1(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][16])[1][0] : Object.values(tableData[0][16])[0][0] ) 
    setQueForteen2(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][16])[1][2] : Object.values(tableData[0][16])[0][2]) 
    setQueForteen3(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][16])[1][6]+''+Object.values(tableData[0][16])[1][7] : Object.values(tableData[0][16])[0][6]+''+Object.values(tableData[0][16])[0][7]) 
    setQueFifteen1(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][17])[1][0] : Object.values(tableData[0][17])[0][0] ) 
    setQueFifteen2(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][17])[1][2] : Object.values(tableData[0][17])[0][2]) 
    setQueFifteen3(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][17])[1][6]+''+Object.values(tableData[0][17])[1][7] : Object.values(tableData[0][17])[0][6]+''+Object.values(tableData[0][17])[0][7]) 
    setQueSixteen1(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][18])[1][0] : Object.values(tableData[0][18])[0][0]) 
    setQueSixteen2(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][18])[1][2] : Object.values(tableData[0][18])[0][2]) 
    setQueSixteen3(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][18])[1][6]+''+Object.values(tableData[0][18])[1][7] : Object.values(tableData[0][18])[0][6]+''+Object.values(tableData[0][18])[0][7]) 
    setQueSeventeen1(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][19])[1][0] : Object.values(tableData[0][19])[0][0]) 
    setQueSeventeen2(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][19])[1][2] : Object.values(tableData[0][19])[0][2]) 
    setQueSeventeen3(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][19])[1][6]+''+Object.values(tableData[0][19])[1][7] : Object.values(tableData[0][19])[0][6]+''+Object.values(tableData[0][19])[0][7]) 
    setQueEighteen1(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][20])[1][0] : Object.values(tableData[0][20])[0][0]) 
    setQueEighteen2(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][20])[1][2] : Object.values(tableData[0][20])[0][2]) 
    setQueEighteen3(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][20])[1][6]+''+Object.values(tableData[0][20])[1][7] : Object.values(tableData[0][20])[0][6]+''+Object.values(tableData[0][20])[0][7]) 
    setQueNinteen1(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][21])[1][0] : Object.values(tableData[0][21])[0][0]) 
    setQueNinteen2(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][21])[1][2] : Object.values(tableData[0][21])[0][2]) 
    setQueNinteen3(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][21])[1][6]+''+Object.values(tableData[0][21])[1][7] : Object.values(tableData[0][21])[0][6]+''+Object.values(tableData[0][21])[0][7]) 
    setQueTwenty1(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][22])[1][0] : Object.values(tableData[0][22])[0][0]) 
    setQueTwenty2(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][22])[0][2] : Object.values(tableData[0][22])[0][2]) 
    setQueTwenty3(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][22])[1][6]+''+Object.values(tableData[0][22])[1][7] : Object.values(tableData[0][22])[0][6]+''+Object.values(tableData[0][22])[0][7]) 
    setQueTwentyOne1(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][23])[1][0] : Object.values(tableData[0][23])[0][0]) 
    setQueTwentyOne2(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][23])[1][2] : Object.values(tableData[0][23])[0][2]) 
    setQueTwentyOne3(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][23])[1][6]+''+Object.values(tableData[0][23])[1][7] : Object.values(tableData[0][23])[0][6]+''+Object.values(tableData[0][23])[0][7]) 
   // setQueTwentyTwo1(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][24])[1][0]: Object.values(tableData[0][24])[0][0]) 
    setQueTwentyTwo2(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][24])[1][2] : Object.values(tableData[0][24])[0][2]) 
    setQueTwentyTwo3(Object.values(tableData[0][3])[1].length !==2 && Object.values(tableData[0][3])[1].length !==3 && Object.values(tableData[0][3])[1].length < 9 ? Object.values(tableData[0][24])[1][6]+''+Object.values(tableData[0][24])[1][7] : Object.values(tableData[0][24])[0][6]+''+Object.values(tableData[0][24])[0][7])
     
    setQue1One1( Object.values(tableData[0][3])[4]? Object.values(tableData[0][3])[4][0] :  Object.values(tableData[0][3])[1][0] )
    setQue1One2(Object.values(tableData[0][3])[4] ? Object.values(tableData[0][3])[4][2] : Object.values(tableData[0][3])[1][2]) 
    setQue1One3(Object.values(tableData[0][3])[4]? Object.values(tableData[0][3])[4][6]+''+Object.values(tableData[0][3])[4][7] : Object.values(tableData[0][3])[1][6]+''+Object.values(tableData[0][3])[1][7]) 
    setQue1Two1(Object.values(tableData[0][4])[4]? Object.values(tableData[0][4])[4][0] : Object.values(tableData[0][4])[1][0] ) 
    setQue1Two2(Object.values(tableData[0][4])[4]? Object.values(tableData[0][4])[4][2] : Object.values(tableData[0][4])[1][2] ) 
    setQue1Two3(Object.values(tableData[0][4])[4]? Object.values(tableData[0][4])[4][6]+''+Object.values(tableData[0][4])[4][7] : Object.values(tableData[0][4])[1][6]+''+Object.values(tableData[0][4])[1][7]) 
    setQue1Three1(Object.values(tableData[0][5])[4]? Object.values(tableData[0][5])[4][0] : Object.values(tableData[0][5])[1][0]) 
    setQue1Three2(Object.values(tableData[0][5])[4]? Object.values(tableData[0][5])[4][2] : Object.values(tableData[0][5])[1][2]) 
    setQue1Three3(Object.values(tableData[0][5])[4]? Object.values(tableData[0][5])[4][6]+''+Object.values(tableData[0][5])[4][7] : Object.values(tableData[0][5])[1][6]);
    setQue1Four1(Object.values(tableData[0][6])[4] ? Object.values(tableData[0][6]+[7])[4][0] : Object.values(tableData[0][6])[1][0]);
    setQue1Four2(Object.values(tableData[0][6])[4] ? Object.values(tableData[0][6]+[7])[4][2] : Object.values(tableData[0][6])[1][2]); 
    setQue1Four3(Object.values(tableData[0][6])[4]? Object.values(tableData[0][6]+[7])[4][6]+''+Object.values(tableData[0][6])[4][7] : Object.values(tableData[0][6]+[7])[1][6]); 
    setQue1Five1(Object.values(tableData[0][7])[4] ? Object.values(tableData[0][7])[4][0] : Object.values(tableData[0][7])[1][0]); 
    setQue1Five2(Object.values(tableData[0][7])[4] ? Object.values(tableData[0][7])[4][2] : Object.values(tableData[0][7])[1][2]); 
    setQue1Five3(Object.values(tableData[0][7])[4] ? Object.values(tableData[0][7])[4][6]+''+Object.values(tableData[0][7])[4][7] : Object.values(tableData[0][7])[1][6]);
    setQue1Six1(Object.values(tableData[0][8])[4] ? Object.values(tableData[0][8])[4][0] : Object.values(tableData[0][8])[1][0]) 
    setQue1Six2(Object.values(tableData[0][8])[4] ? Object.values(tableData[0][8])[4][2] : Object.values(tableData[0][8])[1][2]) 
    setQue1Six3(Object.values(tableData[0][8])[4] ? Object.values(tableData[0][8])[4][6]+''+Object.values(tableData[0][8])[4][7] : Object.values(tableData[0][8])[1][6]+''+Object.values(tableData[0][8])[1][7]) 
    setQue1Seven1(Object.values(tableData[0][9])[4] ? Object.values(tableData[0][9])[4][0] : Object.values(tableData[0][9])[1][0]); 
    setQue1Seven2(Object.values(tableData[0][9])[4]? Object.values(tableData[0][9])[4][2] : Object.values(tableData[0][9])[1][2]);
    setQue1Seven3(Object.values(tableData[0][9])[4] ? Object.values(tableData[0][9])[4][6]+''+Object.values(tableData[0][9])[4][7] : Object.values(tableData[0][9])[1][6]+''+Object.values(tableData[0][9])[1][7]) 
    setQue1Eight1(Object.values(tableData[0][10])[4] ? Object.values(tableData[0][10])[4][0] : Object.values(tableData[0][10])[1][0]) 
    setQue1Eight2(Object.values(tableData[0][10])[4] ? Object.values(tableData[0][10])[4][2] : Object.values(tableData[0][10])[1][2]) 
    setQue1Eight3(Object.values(tableData[0][10])[4] ? Object.values(tableData[0][10])[4][6]+''+Object.values(tableData[0][10])[4][7] : Object.values(tableData[0][10])[1][6]+''+Object.values(tableData[0][10])[1][7]) 
    setQue1Nine1(Object.values(tableData[0][11])[4] ? Object.values(tableData[0][11])[4][0] : Object.values(tableData[0][11])[1][0]) 
    setQue1Nine2(Object.values(tableData[0][11])[4] ? Object.values(tableData[0][11])[4][2] :Object.values(tableData[0][11])[1][2]) 
    setQue1Nine3(Object.values(tableData[0][11])[4] ? Object.values(tableData[0][11])[4][6]+''+Object.values(tableData[0][11])[4][7] : Object.values(tableData[0][11])[1][6]) 
    setQue1Ten1(Object.values(tableData[0][12])[4]? Object.values(tableData[0][12])[4][0] : Object.values(tableData[0][12])[1][0]) 
    setQue1Ten2(Object.values(tableData[0][12])[4] ? Object.values(tableData[0][12])[4][2] : Object.values(tableData[0][12])[1][2]) 
    setQue1Ten3(Object.values(tableData[0][12])[4] ? Object.values(tableData[0][12])[4][6]+''+Object.values(tableData[0][12])[4][7] : Object.values(tableData[0][12])[1][6]) 
    setQue1Ele1(Object.values(tableData[0][13])[4] ? Object.values(tableData[0][13])[4][0]: Object.values(tableData[0][13])[1][0]) 
    setQue1Ele2(Object.values(tableData[0][13])[4] ? Object.values(tableData[0][13])[4][2]: Object.values(tableData[0][13])[1][2]) 
    setQue1Ele3(Object.values(tableData[0][13])[4]? Object.values(tableData[0][13])[4][6]+''+Object.values(tableData[0][13])[4][7]: Object.values(tableData[0][13])[1][6]+''+Object.values(tableData[0][13])[1][7]) 
    setQue1Twle1(Object.values(tableData[0][14])[4] ? Object.values(tableData[0][14])[4][0] : Object.values(tableData[0][14])[1][0]) 
    setQue1Twle2(Object.values(tableData[0][14])[4] ? Object.values(tableData[0][14])[4][2] : Object.values(tableData[0][14])[1][2]) 
    setQue1Twle3(Object.values(tableData[0][14])[4] ? Object.values(tableData[0][14])[4][6]+''+Object.values(tableData[0][14])[4][7] : Object.values(tableData[0][14])[1][6]) 
    setQue1Thirt1(Object.values(tableData[0][15])[4] ? Object.values(tableData[0][15])[4][0] : Object.values(tableData[0][15])[1][0]) 
    setQue1Thirt2(Object.values(tableData[0][15])[4]? Object.values(tableData[0][15])[4][2] : Object.values(tableData[0][15])[1][2]) 
    setQue1Thirt3(Object.values(tableData[0][15])[4] ? Object.values(tableData[0][15])[4][6]+''+Object.values(tableData[0][15])[4][7] : Object.values(tableData[0][15])[1][6]+''+Object.values(tableData[0][15])[1][7]) 
    setQue1Forteen1(Object.values(tableData[0][16])[4] ? Object.values(tableData[0][16])[4][0] : Object.values(tableData[0][16])[1][0]) 
    setQue1Forteen2(Object.values(tableData[0][16])[4] ? Object.values(tableData[0][16])[4][2] : Object.values(tableData[0][16])[1][2]) 
    setQue1Forteen3(Object.values(tableData[0][16])[4] ? Object.values(tableData[0][16])[4][6]+''+Object.values(tableData[0][16])[4][7] : Object.values(tableData[0][16])[1][6]+''+Object.values(tableData[0][16])[1][7]) 
    setQue1Fifteen1(Object.values(tableData[0][17])[4] ? Object.values(tableData[0][17])[4][0] : Object.values(tableData[0][17])[1][0]) 
    setQue1Fifteen2(Object.values(tableData[0][17])[4] ? Object.values(tableData[0][17])[4][2] : Object.values(tableData[0][17])[1][2]) 
    setQue1Fifteen3(Object.values(tableData[0][17])[4] ? Object.values(tableData[0][17])[4][6]+''+Object.values(tableData[0][17])[4][7] : Object.values(tableData[0][17])[1][6]+''+Object.values(tableData[0][17])[1][7]) 
    setQue1Sixteen1(Object.values(tableData[0][18])[4] ? Object.values(tableData[0][18])[4][0] : Object.values(tableData[0][18])[1][0]) 
    setQue1Sixteen2(Object.values(tableData[0][18])[4] ? Object.values(tableData[0][18])[4][2] : Object.values(tableData[0][18])[1][2]) 
    setQue1Sixteen3(Object.values(tableData[0][18])[4] ? Object.values(tableData[0][18])[4][6]+''+Object.values(tableData[0][18])[4][7] : Object.values(tableData[0][18])[1][6]+''+Object.values(tableData[0][18])[1][7]) 
    setQue1Seventeen1(Object.values(tableData[0][19])[4] ? Object.values(tableData[0][19])[4][0] : Object.values(tableData[0][19])[1][0]) 
    setQue1Seventeen2(Object.values(tableData[0][19])[4] ? Object.values(tableData[0][19])[4][2] : Object.values(tableData[0][19])[1][2]) 
    setQue1Seventeen3(Object.values(tableData[0][19])[4] ? Object.values(tableData[0][19])[4][6]+''+Object.values(tableData[0][19])[4][7] : Object.values(tableData[0][19])[1][6]+''+Object.values(tableData[0][19])[1][7]) 
    setQue1Eighteen1(Object.values(tableData[0][20])[4] ? Object.values(tableData[0][20])[4][0] : Object.values(tableData[0][20])[1][0]) 
    setQue1Eighteen2(Object.values(tableData[0][20])[4] ? Object.values(tableData[0][20])[4][2] : Object.values(tableData[0][20])[1][2]) 
    setQue1Eighteen3(Object.values(tableData[0][20])[4] ? Object.values(tableData[0][20])[4][6]+''+Object.values(tableData[0][20])[4][7] : Object.values(tableData[0][20])[1][6]+''+Object.values(tableData[0][20])[1][7]) 
    setQue1Ninteen1(Object.values(tableData[0][21])[4] ? Object.values(tableData[0][21])[4][0] : Object.values(tableData[0][21])[1][0]) 
    setQue1Ninteen2(Object.values(tableData[0][21])[4] ? Object.values(tableData[0][21])[4][2] : Object.values(tableData[0][21])[1][2]) 
    setQue1Ninteen3(Object.values(tableData[0][21])[4] ? Object.values(tableData[0][21])[4][6]+''+Object.values(tableData[0][21])[4][7] : Object.values(tableData[0][21])[1][6]+''+Object.values(tableData[0][21])[1][7] ) 
    setQue1Twenty1(Object.values(tableData[0][22])[4] ? Object.values(tableData[0][22])[4][0] : Object.values(tableData[0][22])[1][0]) 
    setQue1Twenty2(Object.values(tableData[0][22])[4] ? Object.values(tableData[0][22])[4][2] : Object.values(tableData[0][22])[1][2]) 
    setQue1Twenty3(Object.values(tableData[0][22])[4] ? Object.values(tableData[0][22])[4][6]+''+Object.values(tableData[0][22])[4][7]: Object.values(tableData[0][22])[1][6]+''+Object.values(tableData[0][22])[1][7]) 
    setQue1TwentyOne1(Object.values(tableData[0][23])[4] ? Object.values(tableData[0][23])[4][0] : Object.values(tableData[0][23])[1][0] ) 
    setQue1TwentyOne2(Object.values(tableData[0][23])[4] ? Object.values(tableData[0][23])[4][2] : Object.values(tableData[0][23])[1][2]) 
    setQue1TwentyOne3(Object.values(tableData[0][23])[4] ? Object.values(tableData[0][23])[4][6]+''+Object.values(tableData[0][23])[4][7] : Object.values(tableData[0][23])[1][6]+''+Object.values(tableData[0][23])[1][7]) 
    setQue1TwentyTwo1(Object.values(tableData[0][24])[4] ? Object.values(tableData[0][24])[4][0] : Object.values(tableData[0][24])[1][0]) 
    setQue1TwentyTwo2(Object.values(tableData[0][24])[4] ? Object.values(tableData[0][24])[4][2] : Object.values(tableData[0][24])[1][2]) 
    setQue1TwentyTwo3(Object.values(tableData[0][24])[4] ? Object.values(tableData[0][24])[4][6]+''+Object.values(tableData[0][24])[4][7] : Object.values(tableData[0][24])[1][6]+''+Object.values(tableData[0][24])[1][7])
  }

  const codeFun0 = (event) => {
    console.log(event.target.value);
    setCode0(event.target.value);
  }
  const codeFun1 = (event) => {
    console.log(event.target.value);
    setCode1(event.target.value);
  }
  const codeFun2 = (event) => {
    console.log(event.target.value);
    setCode2(event.target.value);
  }
  const codeFun3 = (event) => {
    console.log(event.target.value);
    setCode3(event.target.value);
  }
  const codeFun4 = (event) => {
    console.log(event.target.value);
    setCode4(event.target.value);
  }

  const UniqueCodeFun0 = (event) => {
    console.log(event.target.value);
    setUniqueCode0(event.target.value);
  }
  const UniqueCodeFun1 = (event) => {
    console.log(event.target.value);
    setUniqueCode1(event.target.value);
  }
  const UniqueCodeFun2 = (event) => {
    console.log(event.target.value);
    setUniqueCode2(event.target.value);
  }
  const UniqueCodeFun3 = (event) => {
    console.log(event.target.value);
    setUniqueCode3(event.target.value);
  }
  const UniqueCodeFun4 = (event) => {
    console.log(event.target.value);
    setUniqueCode4(event.target.value);
  }


  const IndexCodeFun0 = (event) => {
    console.log(event.target.value);
    setIndexCode0(event.target.value);
  }
  const IndexCodeFun1 = (event) => {
    console.log(event.target.value);
    setIndexCode1(event.target.value);
  }
  const IndexCodeFun2 = (event) => {
    console.log(event.target.value);
    setIndexCode2(event.target.value);
  }
  const IndexCodeFun3 = (event) => {
    console.log(event.target.value);
    setIndexCode3(event.target.value);
  }
  const IndexCodeFun4 = (event) => {
    console.log(event.target.value);
    setIndexCode4(event.target.value);
  }
  const IndexCodeFun5 = (event) => {
    console.log(event.target.value);
    setIndexCode5(event.target.value);
  }
  const IndexCodeFun6 = (event) => {
    console.log(event.target.value);
    setIndexCode6(event.target.value);
  }
  const IndexCodeFun7 = (event) => {
    console.log(event.target.value);
    setIndexCode7(event.target.value);
  }
  const IndexCodeFun8 = (event) => {
    console.log(event.target.value);
    setIndexCode8(event.target.value);
  }
  const IndexCodeFun9 = (event) => {
    console.log(event.target.value);
    setIndexCode9(event.target.value);
  }

  const SubjectCodeFun0 = (event) => {
    console.log(event.target.value);
    setSubjectCode0(event.target.value);
  }
  const SubjectCodeFun1 = (event) => {
    console.log(event.target.value);
    setSubjectCode1(event.target.value);
  }

  const PageNoFun = (event) => {
    console.log(event.target.value);
    setPageNo(event.target.value);
  }

  const YearExaminationFun = (event) => {
    console.log(event.target.value);
    setYearExamination3(event.target.value);
  }
  const YearExaminationFun1 = (event) => {
    console.log(event.target.value);
    setYearExamination1(event.target.value);
  }
  const YearExaminationFun2 = (event) => {
    console.log(event.target.value);
    setYearExamination2(event.target.value);
  }
  const YearExaminationFun3 = (event) => {
    console.log(event.target.value);
    setYearExamination3(event.target.value);
  }

  const InitialsOFExaminarFun = (event) => {
    setInitialsOFExaminar(event.target.value);
  }

  const dateFun = (event) => {
    setDate(event.target.value);
  }

  const dateFun1 = (event) => {
    setDate1(event.target.value);
  }

  const dateFun2 = (event) => {
    setDate2(event.target.value);
  }

  const dateFun3 = (event) => {
    setDate3(event.target.value);
  }

  const dateFun4 = (event) => {
    setDate5(event.target.value);
  }

  const dateFun5 = (event) => {
    setDate6(event.target.value);
  }

  const dateFun6 = (event) => {
    setDate7(event.target.value);
  }

  const dateFun7 = (event) => {
    setDate7(event.target.value);
  }

  const timeFun = (event) => {
    setTime(event.target.value);
  }


  const nameFun = (event) => {
    setName(event.target.value);
  }

  const subjectFun = (event) => {
    setSubject(event.target.value);
  }

  const YearExaminationFunFull = (event) => {
    setYearOfExaminationFfull(event.target.value);
  }

  const UniqueIdFullFun = (event) => {
    SetUniqueIdFull(event.target.value)
  }

  const IndexCodeFullFun = (event) => {
    setFullIndexNo(event.target.value)
  }

  const SignatureFun = (event) => {
    setSignature(event.target.value);
  }

  const lineOneQue = (event) => {
    console.log(event.target.name)
    if(event.target.name === "QueOne1"){
      setQueOne1(event.target.value);
    }
    if(event.target.name === "QueOne2"){
      setQueOne2(event.target.value);
    }
    if(event.target.name === "QueOne3"){
      setQueOne3(event.target.value);
    }
    if(event.target.name === "QueTwo1"){
      setQueTwo1(event.target.value);
    }
    if(event.target.name === "QueTwo2"){
      setQueTwo2(event.target.value);
    }
    if(event.target.name === "QueTwo3"){
      setQueTwo3(event.target.value);
    }
    if(event.target.name === "QueThree1"){
      setQueThree1(event.target.value);
    }
    if(event.target.name === "QueThree2"){
      setQueThree2(event.target.value);
    }
    if(event.target.name === "QueThree3"){
      setQueThree3(event.target.value);
    }
    if(event.target.name === "QueFour1"){
      setQueFour1(event.target.value);
    }
    if(event.target.name === "QueFour2"){
      setQueFour2(event.target.value);
    }
    if(event.target.name === "QueFour3"){
      setQueFour3(event.target.value);
    }
    if(event.target.name === "QueFive1"){
      setQueFive1(event.target.value);
    }
    if(event.target.name === "QueFive2"){
      setQueFive2(event.target.value);
    }
    if(event.target.name === "QueFive3"){
      setQueFive3(event.target.value);
    }
    if(event.target.name === "QueSix1"){
      setQueSix1(event.target.value);
    }
    if(event.target.name === "QueSix2"){
      setQueSix2(event.target.value);
    }
    if(event.target.name === "QueSix3"){
      setQueSix3(event.target.value);
    }
    if(event.target.name === "QueSeven1"){
      setQueSeven1(event.target.value);
    }
    if(event.target.name === "QueSeven2"){
      setQueSeven2(event.target.value);
    }
    if(event.target.name === "QueSeven3"){
      setQueSeven3(event.target.value);
    }
    if(event.target.name === "QueEight1"){
      setQueEight1(event.target.value);
    }
    if(event.target.name === "QueEight2"){
      setQueEight2(event.target.value);
    }
    if(event.target.name === "QueEight3"){
      setQueEight3(event.target.value);
    }
    if(event.target.name === "QueNine1"){
      setQueNine1(event.target.value);
    }
    if(event.target.name === "QueNine2"){
      setQueNine2(event.target.value);
    }
    if(event.target.name === "QueNine3"){
      setQueNine3(event.target.value);
    }
    if(event.target.name === "QueTen1"){
      setQueTen1(event.target.value);
    }
    if(event.target.name === "QueTen2"){
      setQueTen2(event.target.value);
    }
    if(event.target.name === "QueTen3"){
      setQueTen3(event.target.value);
    }
    if(event.target.name === "QueEle1"){
      setQueEle1(event.target.value);
    }
    if(event.target.name === "QueEle2"){
      setQueEle2(event.target.value);
    }
    if(event.target.name === "QueEle3"){
      setQueEle3(event.target.value);
    }
    if(event.target.name === "QueTwle1"){
      setQueTwle1(event.target.value);
    }
    if(event.target.name === "QueTwle2"){
      setQueTwle2(event.target.value);
    }
    if(event.target.name === "QueTwle3"){
      setQueTwle3(event.target.value);
    }
    if(event.target.name === "QueThirt1"){
      setQueThirt1(event.target.value);
    }
    if(event.target.name === "QueThirt2"){
      setQueThirt2(event.target.value);
    }
    if(event.target.name === "QueThirt3"){
      setQueThirt3(event.target.value);
    }
    if(event.target.name === "QueForteen1"){
      setQueForteen1(event.target.value);
    }
    if(event.target.name === "QueForteen2"){
      setQueForteen2(event.target.value);
    }
    if(event.target.name === "QueForteen3"){
      setQueForteen3(event.target.value);
    }
    if(event.target.name === "QueFifteen1"){
      setQueFifteen1(event.target.value);
    }
    if(event.target.name === "QueFifteen2"){
      setQueFifteen2(event.target.value);
    }
    if(event.target.name === "QueFifteen3"){
      setQueFifteen3(event.target.value);
    }
    if(event.target.name === "QueSixteen1"){
      setQueSixteen1(event.target.value);
    }
    if(event.target.name === "QueSixteen2"){
      setQueSixteen2(event.target.value);
    }
    if(event.target.name === "QueSixteen3"){
      setQueSixteen3(event.target.value);
    }
    if(event.target.name === "QueSeventeen1"){
      setQueSeventeen1(event.target.value);
    }
    if(event.target.name === "QueSeventeen2"){
      setQueSeventeen2(event.target.value);
    }
    if(event.target.name === "QueSeventeen3"){
      setQueSeventeen3(event.target.value);
    }
    if(event.target.name === "QueEighteen1"){
      setQueEighteen1(event.target.value);
    }
    if(event.target.name === "QueEighteen2"){
      setQueEighteen2(event.target.value);
    }
    if(event.target.name === "QueEighteen3"){
      setQueEighteen3(event.target.value);
    }
    if(event.target.name === "QueNinteen1"){
      setQueNinteen1(event.target.value);
    }
    if(event.target.name === "QueNinteen2"){
      setQueNinteen2(event.target.value);
    }
    if(event.target.name === "QueNinteen3"){
      setQueNinteen3(event.target.value);
    }
    if(event.target.name === "QueTwenty1"){
      setQueTwenty1(event.target.value);
    }
    if(event.target.name === "QueTwenty2"){
      setQueTwenty2(event.target.value);
    }
    if(event.target.name === "QueTwenty3"){
      setQueTwenty3(event.target.value);
    }
    if(event.target.name === "QueTwentyOne1"){
      setQueTwentyOne1(event.target.value);
    }
    if(event.target.name === "QueTwentyOne2"){
      setQueTwentyOne2(event.target.value);
    }
    if(event.target.name === "QueTwentyOne3"){
      setQueTwentyOne3(event.target.value);
    }
    if(event.target.name === "QueTwentyTwo1"){
      setQueTwentyTwo1(event.target.value);
    }
    if(event.target.name === "QueTwentyTwo2"){
      setQueTwentyTwo1(event.target.value);
    }
    if(event.target.name === "QueTwentyTwo3"){
      setQueTwentyTwo3(event.target.value);
    } 
    
  }

  const lineTwoQue = (event) => {
    console.log(event.target.value)
    if(event.target.name === "Que1One1"){
      setQue1One1(event.target.value);
    }
    if(event.target.name === "Que1One2"){
      setQue1One2(event.target.value);
    }
    if(event.target.name === "Que1One3"){
      setQue1One3(event.target.value);
    }
    if(event.target.name === "Que1Two1"){
      setQue1Two1(event.target.value);
    }
    if(event.target.name === "Que1Two2"){
      setQue1Two2(event.target.value);
    }
    if(event.target.name === "Que1Two3"){
      setQue1Two3(event.target.value);
    }
    if(event.target.name === "Que1Three1"){
      setQue1Three1(event.target.value);
    }
    if(event.target.name === "Que1Three2"){
      setQue1Three2(event.target.value);
    }
    if(event.target.name === "Que1Three3"){
      setQue1Three3(event.target.value);
    }
    if(event.target.name === "Que1Four1"){
      setQue1Four1(event.target.value);
    }
    if(event.target.name === "Que1Four2"){
      setQue1Four2(event.target.value);
    }
    if(event.target.name === "Que1Four3"){
      setQue1Four3(event.target.value);
    }
    if(event.target.name === "Que1Five1"){
      setQue1Five1(event.target.value);
    }
    if(event.target.name === "Que1Five2"){
      setQue1Five2(event.target.value);
    }
    if(event.target.name === "Que1Five3"){
      setQue1Five3(event.target.value);
    }
    if(event.target.name === "Que1Six1"){
      setQue1Six1(event.target.value);
    }
    if(event.target.name === "Que1Six2"){
      setQue1Six2(event.target.value);
    }
    if(event.target.name === "Que1Six3"){
      setQue1Six3(event.target.value);
    }
    if(event.target.name === "Que1Seven1"){
      setQue1Seven1(event.target.value);
    }
    if(event.target.name === "Que1Seven2"){
      setQue1Seven2(event.target.value);
    }
    if(event.target.name === "Que1Seven3"){
      setQue1Seven3(event.target.value);
    }
    if(event.target.name === "Que1Eight1"){
      setQue1Eight1(event.target.value);
    }
    if(event.target.name === "Que1Eight2"){
      setQue1Eight2(event.target.value);
    }
    if(event.target.name === "Que1Eight3"){
      setQue1Eight3(event.target.value);
    }
    if(event.target.name === "Que1Nine1"){
      setQue1Nine1(event.target.value);
    }
    if(event.target.name === "Que1Nine2"){
      setQue1Nine2(event.target.value);
    }
    if(event.target.name === "Que1Nine3"){
      setQue1Nine3(event.target.value);
    }
    if(event.target.name === "Que1Ten1"){
      setQue1Ten1(event.target.value);
    }
    if(event.target.name === "Que1Ten2"){
      setQue1Ten2(event.target.value);
    }
    if(event.target.name === "Que1Ten3"){
      setQue1Ten3(event.target.value);
    }
    if(event.target.name === "Que1Ele1"){
      setQue1Ele1(event.target.value);
    }
    if(event.target.name === "Que1Ele2"){
      setQue1Ele2(event.target.value);
    }
    if(event.target.name === "Que1Ele3"){
      setQue1Ele3(event.target.value);
    }
    if(event.target.name === "Que1Twle1"){
      setQue1Twle1(event.target.value);
    }
    if(event.target.name === "Que1Twle2"){
      setQue1Twle2(event.target.value);
    }
    if(event.target.name === "Que1Twle3"){
      setQue1Twle3(event.target.value);
    }
    if(event.target.name === "Que1Thirt1"){
      setQue1Thirt1(event.target.value);
    }
    if(event.target.name === "Que1Thirt2"){
      setQue1Thirt2(event.target.value);
    }
    if(event.target.name === "Que1Thirt3"){
      setQue1Thirt3(event.target.value);
    }
    if(event.target.name === "Que1Forteen1"){
      setQue1Forteen1(event.target.value);
    }
    if(event.target.name === "Que1Forteen2"){
      setQue1Forteen2(event.target.value);
    }
    if(event.target.name === "Que1Forteen3"){
      setQue1Forteen3(event.target.value);
    }
    if(event.target.name === "Que1Fifteen1"){
      setQue1Fifteen1(event.target.value);
    }
    if(event.target.name === "Que1Fifteen2"){
      setQue1Fifteen2(event.target.value);
    }
    if(event.target.name === "Que1Fifteen3"){
      setQue1Fifteen3(event.target.value);
    }
    if(event.target.name === "Que1Sixteen1"){
      setQue1Sixteen1(event.target.value);
    }
    if(event.target.name === "Que1Sixteen2"){
      setQue1Sixteen2(event.target.value);
    }
    if(event.target.name === "Que1Sixteen3"){
      setQue1Sixteen3(event.target.value);
    }
    if(event.target.name === "Que1Seventeen1"){
      setQue1Seventeen1(event.target.value);
    }
    if(event.target.name === "Que1Seventeen2"){
      setQue1Seventeen2(event.target.value);
    }
    if(event.target.name === "Que1Seventeen3"){
      setQue1Seventeen3(event.target.value);
    }
    if(event.target.name === "Que1Eighteen1"){
      setQue1Eighteen1(event.target.value);
    }
    if(event.target.name === "Que1Eighteen2"){
      setQue1Eighteen2(event.target.value);
    }
    if(event.target.name === "Que1Eighteen3"){
      setQue1Eighteen3(event.target.value);
    }
    if(event.target.name === "Que1Ninteen1"){
      setQue1Ninteen1(event.target.value);
    }
    if(event.target.name === "Que1Ninteen2"){
      setQue1Ninteen2(event.target.value);
    }
    if(event.target.name === "Que1Ninteen3"){
      setQue1Ninteen3(event.target.value);
    }
    if(event.target.name === "Que1Twenty1"){
      setQue1Twenty1(event.target.value);
    }
    if(event.target.name === "Que1Twenty2"){
      setQue1Twenty2(event.target.value);
    }
    if(event.target.name === "Que1Twenty3"){
      setQue1Twenty3(event.target.value);
    }
    if(event.target.name === "Que1TwentyOne1"){
      setQue1TwentyOne1(event.target.value);
    }
    if(event.target.name === "Que1TwentyOne2"){
      setQue1TwentyOne2(event.target.value);
    }
    if(event.target.name === "Que1TwentyOne3"){
      setQue1TwentyOne3(event.target.value);
    }
    if(event.target.name === "Que1TwentyTwo1"){
      setQue1TwentyTwo1(event.target.value);
    }
    if(event.target.name === "Que1TwentyTwo2"){
      setQue1TwentyTwo1(event.target.value);
    }
    if(event.target.name === "Que1TwentyTwo3"){
      setQue1TwentyTwo3(event.target.value);
    } 
    
  }

  useEffect(() => {
    console.log("**")
    makeData();
  }, []);


  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card plain>
          <CardHeader plain color="primary">
            <h4 className={classes.cardTitleWhite}>OMCS HTML SHEET</h4>
            <p className={classes.cardCategoryWhite}>
              Sheet View
            </p>
          </CardHeader>
          <CardBody>
            <header class="header">
              <div class="header-menu">
                <div class="container">
                  <nav class="navbar navbar-default navbar-expand-lg navbar-light header-navbar">
                    <a class="navbar-brand navbar-brand" href="#"> <h3> DNA Education </h3> </a>
                  </nav>
                </div>
              </div>
            </header>
            <div class="main-body">
              <div class="body-section">
                <div class="container">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="border-col">
                        <h4> To be Filled in by the Teacher (ONLY if Required) </h4>
                        <div class="box-col">
                          <h5> Please enter the code shown on the Table Device </h5>
                          <div class="form-check form-check-inline from-col">
                            <input type="text" value={code0} onChange={codeFun0} class="form-control" />
                            <input type="text" value={code1} onChange={codeFun1} class="form-control" />
                            <input type="text" value={code2} onChange={codeFun2} class="form-control" />
                            <input type="text" value={code3} onChange={codeFun3} class="form-control" />
                            <input type="text" value={code4} onChange={codeFun4} class="form-control" />
                          </div>
                          <p> Note: Use thisprovision ONLY when the marks entered Either on the LICR Top-sheet OR on this Generic LICR Top-sheet have became ellegible due to multiple over_writing for same other reason and you wish to enter marks on a new Generic Top-sheet. </p>
                        </div>
                        <h4> To be Filed in by the teacher/Parent </h4>
                        <div class="box-col box-col-list">
                          <h5> Please enter the detalis of the Candidate </h5>
                          <h6> Unique ID: </h6>
                          <div class="form-check form-check-inline from-col">
                            <input type="text" value={UniqueCode0} onChange={UniqueCodeFun0} class="form-control" />
                            <input type="text" value={UniqueCode1} onChange={UniqueCodeFun1} class="form-control" />
                            <input type="text" value={UniqueCode2} onChange={UniqueCodeFun2} class="form-control" />
                            <input type="text" value={UniqueCode3} onChange={UniqueCodeFun3} class="form-control" />
                            <input type="text" value={UniqueCode4} onChange={UniqueCodeFun4} class="form-control" />
                          </div>
                          <h6> Index No: </h6>
                          <div class="form-check form-check-inline from-col">
                            <input type="text" value={IndexCode0} onChange={IndexCodeFun0} class="form-control" />
                            <input type="text" value={IndexCode1} onChange={IndexCodeFun1} class="form-control" />
                            <input type="text" value={IndexCode2} onChange={IndexCodeFun2} class="form-control" />
                            <input type="text" value={IndexCode3} onChange={IndexCodeFun3} class="form-control" />
                            <input type="text" value={IndexCode4} onChange={IndexCodeFun4} class="form-control" />
                            <input type="text" value={IndexCode5} onChange={IndexCodeFun5} class="form-control" />
                            <input type="text" value={IndexCode6} onChange={IndexCodeFun6} class="form-control" />
                            <div class="divider-form">  / </div>
                            <input type="text" value={IndexCode7} onChange={IndexCodeFun7} class="form-control" />
                            <input type="text" value={IndexCode8} onChange={IndexCodeFun8} class="form-control" />
                            <input type="text" value={IndexCode9} onChange={IndexCodeFun9} class="form-control" />
                          </div>
                          <div class="form-list-exam">
                            <h6> Subject Code: </h6>
                            <div class="form-check form-check-inline from-col">
                              <input type="text" value={SubjectCode0} onChange={SubjectCodeFun0} class="form-control" />
                              <input type="text" value={SubjectCode1} onChange={SubjectCodeFun1} class="form-control" />
                            </div>
                          </div>
                          <div class="form-list-exam form-list-exam-2">
                            <h6> Paper No: </h6>
                            <div class="form-check form-check-inline from-col">
                              <input type="text" value={PageNo} onChange={PageNoFun} class="form-control" />
                            </div>
                          </div>
                          <div class="form-list-exam">
                            <h6> Year of Examination: </h6>
                            <div class="form-check form-check-inline from-col">
                              <input type="text" value={YearExamination} onChange={YearExaminationFun} class="form-control" />
                              <input type="text" value={YearExamination1} onChange={YearExaminationFun1} class="form-control" />
                              <input type="text" value={YearExamination2} onChange={YearExaminationFun2} class="form-control" />
                              <input type="text" value={YearExamination3} onChange={YearExaminationFun3} class="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="border-col">
                        <h4> To be filled in by the Supervising Teacher/Parent </h4>
                        <div class="box-col box-col-list">
                          <div class="custom-examiner-col">
                            <h5> Initials of the Supervising Examiner: </h5>
                            <input type="text" value={InitialsOFExaminar} onChange={InitialsOFExaminarFun} class="form-control" />
                          </div>
                          <div class="form-check form-check-inline from-col">
                            <label>Date:</label>
                            <input type="text" value={date} onChange={dateFun} class="form-control" />
                            <input type="text" value={date1} onChange={dateFun1} class="form-control" />
                            <input type="text" value={date2} onChange={dateFun2} class="form-control" />
                            <input type="text" value={date3} onChange={dateFun3} class="form-control" />
                            <input type="text" value={date4} onChange={dateFun4} class="form-control" />
                            <input type="text" value={date5} onChange={dateFun5} class="form-control" />
                            <input type="text" value={date6} onChange={dateFun6} class="form-control" />
                            <input type="text" value={date7} onChange={dateFun7} class="form-control" />
                          </div>

                          <div class="form-check form-check-inline from-col from-col-date">
                            <label>Time:</label>
                            <input type="text" value={time} onChange={timeFun} class="form-control" />
                            <span class="time-col"> AM </span>
                            <span class="time-col"> PM </span>
                          </div>

                        </div>
                      </div>

                      <div class="border-col">
                        <h4> To be filled in by the Candidate (in CAPITAL LETTRES) </h4>
                        <div class="box-col box-col-list">
                          <div class="form-check form-check-inline from-col from-col-list-data">
                            <form>
                              <div class="form-group row">
                                <label class="col-sm-4 col-form-label">Name</label>
                                <div class="col-sm-8">
                                  <input type="text" value={name} onChange={nameFun} class="form-control" />
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-sm-4 col-form-label">Subject</label>
                                <div class="col-sm-8">
                                  <input type="text" value={subject} onChange={subjectFun} class="form-control" />
                                </div>
                              </div>
                              <div class="form-row row form-row-inline-custom">
                                <div class="col phone-col-custom">
                                  <label >Paper No: </label>
                                  <input type="text" value={PageNo} onChange={PageNoFun} class="form-control" />
                                </div>
                                <div class="col">
                                  <label>Year of Examination</label>
                                  <input type="text" value={YearOfExaminationFfull} onChange={YearExaminationFunFull} class="form-control" />
                                </div>
                              </div>
                              <div class="form-row row form-row-inline-custom form-row-inline-custom-1">
                                <div class="col phone-col-custom">
                                  <label > UniQue Id: </label>
                                  <input type="text" value={UniqueIdFull} onChange={UniqueIdFullFun} class="form-control" />
                                </div>
                                <div class="col">
                                  <label>Index NO:</label>
                                  <input type="text" value={FullIndexNo} onChange={IndexCodeFullFun} class="form-control" />
                                </div>
                              </div>
                              <div class="form-group textarea-text">
                                <label> Signature of the Candidate: </label>
                                <textarea class="form-control" value={signature} onChange={SignatureFun} rows="3"></textarea>
                              </div>
                              <span class="note-text"> Note: DO not Write anything else on this Generic Top-sheet </span>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 border-left">
                      <div class="border-col border-col-1">
                        <h4> To be Filled in by the Teacher/ Parent </h4>
                        <div class="right-table-box">
                          <div class="left-col">
                            <h5> Bundle Number: </h5>
                            <div class="input-bundle-box">
                              <input type="text" class="form-control" />
                              <input type="text" class="form-control" />
                            </div>

                            <div class="left-col-second">
                              <h5> Packing Serial Number </h5>
                              <div class="input-bundle-box">
                                <input type="text" class="form-control" />
                                <input type="text" class="form-control" />
                              </div>
                            </div>

                            <div class="left-col-large-col-from">
                              <h5> Teacher Parent Signature:  </h5>
                              <div class="input-bundle-box-large">
                                <input type="text" class="form-control" />
                              </div>
                            </div>

                          </div>
                          <div class="right-col-table">
                            <table class="table">
                              <thead>
                                <tr>

                                  <th class="border-right box-width-samll">Q No.</th>
                                  <th>Marks</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>

                                  <td class="border-right box-width-samll"> 1 </td>
                                  <td>
                                    <div class="input-box-with-dot">
                                      <input type="text" name="QueOne1" value={QueOne1} onChange={lineOneQue} class="form-control" />
                                      <input type="text" name="QueOne2" value={QueOne2} onChange={lineOneQue} class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="QueOne3" value={QueOne3} onChange={lineOneQue} class="form-control" />
                                    </div>
                                  </td>
                                </tr>
                                <tr>

                                  <td class="border-right box-width-samll"> 2 </td>
                                  <td>
                                    <div class="input-box-with-dot">
                                      <input type="text" name="QueTwo1" value={QueTwo1} onChange={lineOneQue} class="form-control" />
                                      <input type="text" name="QueTwo2" value={QueTwo2} onChange={lineOneQue} class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="QueTwo3" value={QueTwo3} onChange={lineOneQue} class="form-control" />
                                    </div>
                                  </td>
                                </tr>
                                <tr>

                                  <td class="border-right box-width-samll"> 3 </td>
                                  <td>
                                    <div class="input-box-with-dot">
                                      <input type="text" name="QueThree1" value={QueThree1} onChange={lineOneQue} class="form-control" />
                                      <input type="text" name="QueThree2" value={QueThree2} onChange={lineOneQue} class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="QueThree3" value={QueThree3} onChange={lineOneQue} class="form-control" />
                                    </div>
                                  </td>
                                </tr>
                                <tr>

                                  <td class="border-right box-width-samll"> 4 </td>
                                  <td>
                                    <div class="input-box-with-dot">
                                    <input type="text" name="QueFour1" value={QueFour1} onChange={lineOneQue} class="form-control" class="form-control" />
                                      <input type="text" name="QueFour2" value={QueFour2} onChange={lineOneQue} class="form-control" class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="QueFour3" value={QueFour3} onChange={lineOneQue} class="form-control" class="form-control" />
                                    </div>
                                  </td>
                                </tr>
                                <tr>

                                  <td class="border-right box-width-samll"> 5 </td>
                                  <td>
                                    <div class="input-box-with-dot">
                                      <input type="text" name="QueFive1" value={QueFive1} onChange={lineOneQue} class="form-control" />
                                      <input type="text" name="QueFive2" value={QueFive2} onChange={lineOneQue} class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="QueFive3" value={QueFive3} onChange={lineOneQue} class="form-control" />
                                    </div>
                                  </td>
                                </tr>
                                <tr>

                                  <td class="border-right box-width-samll"> 6 </td>
                                  <td>
                                    <div class="input-box-with-dot">
                                      <input type="text" name="QueSix1" value={QueSix1} onChange={lineOneQue} class="form-control" />
                                      <input type="text" name="QueSix2" value={QueSix2} onChange={lineOneQue} class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="QueSix3" value={QueSix3} onChange={lineOneQue} class="form-control" />
                                    </div>
                                  </td>
                                </tr>
                                <tr>

                                  <td class="border-right box-width-samll"> 7 </td>
                                  <td>
                                    <div class="input-box-with-dot">
                                      <input type="text" name="QueSeven1" value={QueSeven1} onChange={lineOneQue} class="form-control" />
                                      <input type="text" name="QueSeven2" value={QueSeven2} onChange={lineOneQue} class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="QueSeven3" value={QueSeven3} onChange={lineOneQue} class="form-control" />
                                    </div>
                                  </td>
                                </tr>
                                <tr>

                                  <td class="border-right box-width-samll"> 8 </td>
                                  <td>
                                    <div class="input-box-with-dot">
                                      <input type="text" name="QueEight1" value={QueEight1} onChange={lineOneQue} class="form-control" />
                                      <input type="text" name="QueEight2" value={QueEight2} onChange={lineOneQue} class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="QueEight3" value={QueEight3} onChange={lineOneQue}  class="form-control" />
                                    </div>
                                  </td>
                                </tr>
                                <tr>

                                  <td class="border-right box-width-samll"> 9 </td>
                                  <td>
                                    <div class="input-box-with-dot">

                                      <input type="text" name="QueNine1" value={QueNine1} onChange={lineOneQue} class="form-control" />
                                      <input type="text" name="QueNine2" value={QueNine2} onChange={lineOneQue} class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="QueNine3" value={QueNine3} onChange={lineOneQue} class="form-control" />

                                    </div>
                                  </td>
                                </tr>
                                <tr>

                                  <td class="border-right box-width-samll"> 10 </td>
                                  <td>
                                    <div class="input-box-with-dot">
                                      <input type="text" name="QueTen1" value={QueTen1} onChange={lineOneQue} class="form-control" />
                                      <input type="text" name="QueTen2" value={QueTen2} onChange={lineOneQue} class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="QueTen3" value={QueTen3} onChange={lineOneQue} class="form-control" />
                                    </div>
                                  </td>
                                </tr>
                                <tr>

                                <td class="border-right box-width-samll"> 11 </td>
                                  <td>
                                    <div class="input-box-with-dot">
                                      <input type="text" name="QueEle1" value={QueEle1} onChange={lineOneQue} class="form-control" />
                                      <input type="text" name="QueEle2" value={QueEle2} onChange={lineOneQue} class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="QueEle3" value={QueEle3} onChange={lineOneQue} class="form-control" />
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td class="border-right box-width-samll"> 12 </td>
                                  <td>
                                    <div class="input-box-with-dot">
                                      <input type="text" name="QueTwle1" value={QueTwle1} onChange={lineOneQue} class="form-control" />
                                      <input type="text" name="QueTwle2" value={QueTwle2} onChange={lineOneQue} class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="QueTwle3" value={QueTwle3} onChange={lineOneQue} class="form-control" />
                                    </div>
                                  </td>
                                </tr>
                                <tr>

                                  <td class="border-right box-width-samll"> 13 </td>
                                  <td>
                                    <div class="input-box-with-dot">
                                      <input type="text" name="QueThirt1" value={QueThirt1} onChange={lineOneQue} class="form-control" />
                                      <input type="text" name="QueThirt2" value={QueThirt2} onChange={lineOneQue} class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="QueThirt3" value={QueThirt3} onChange={lineOneQue} class="form-control" />
                                    </div>
                                  </td>
                                </tr>
                                <tr>

                                  <td class="border-right box-width-samll"> 14 </td>
                                  <td>
                                    <div class="input-box-with-dot">
                                      <input type="text" name="QueForteen1" value={QueForteen1} onChange={lineOneQue} class="form-control" />
                                      <input type="text" name="QueForteen2" value={QueForteen2} onChange={lineOneQue} class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="QueForteen3" value={QueForteen3} onChange={lineOneQue} class="form-control" />
                                    </div>
                                  </td>
                                </tr>
                                <tr>

                                  <td class="border-right box-width-samll"> 15 </td>
                                  <td>
                                    <div class="input-box-with-dot">
                                      <input type="text" name="QueFifteen1" value={QueFifteen1} onChange={lineOneQue} class="form-control" />
                                      <input type="text" name="QueFifteen2" value={QueFifteen2} onChange={lineOneQue} class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="QueFifteen3" value={QueFifteen3} onChange={lineOneQue} class="form-control" />
                                    </div>
                                  </td>
                                </tr>
                                <tr>

                                  <td class="border-right box-width-samll"> 16 </td>
                                  <td>
                                    <div class="input-box-with-dot">
                                      <input type="text" name="QueSixteen1" value={QueSixteen1} onChange={lineOneQue} class="form-control" />
                                      <input type="text" name="QueSixteen2" value={QueSixteen2} onChange={lineOneQue} class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="QueSixteen3" value={QueSixteen3} onChange={lineOneQue} class="form-control" />
                                    </div>
                                  </td>
                                </tr>
                                <tr>

                                  <td class="border-right box-width-samll"> 17 </td>
                                  <td>
                                    <div class="input-box-with-dot">
                                      <input type="text" name="QueSeventeen1" value={QueSeventeen1} onChange={lineOneQue} class="form-control" />
                                      <input type="text" name="QueSeventeen2" value={QueSeventeen2} onChange={lineOneQue} class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="QueSeventeen3" value={QueSeventeen3} onChange={lineOneQue} class="form-control" />
                                    </div>
                                  </td>
                                </tr>
                                <tr>

                                  <td class="border-right box-width-samll"> 18 </td>
                                  <td>
                                    <div class="input-box-with-dot">
                                      <input type="text" name="QueEighteen1" value={QueEighteen1} onChange={lineOneQue} class="form-control" />
                                      <input type="text" name="QueEighteen2" value={QueEighteen2} onChange={lineOneQue} class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="QueEighteen3" value={QueEighteen3} onChange={lineOneQue} class="form-control" />
                                    </div>
                                  </td>
                                </tr>
                                <tr>

                                  <td class="border-right box-width-samll"> 19 </td>
                                  <td>
                                    <div class="input-box-with-dot">
                                      
                                      <input type="text" name="QueNinteen1" value={QueNinteen1} onChange={lineOneQue} class="form-control" />
                                      <input type="text" name="QueNinteen2" value={QueNinteen2} onChange={lineOneQue} class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="QueNinteen3" value={QueNinteen3} onChange={lineOneQue} class="form-control" />

                                    </div>
                                  </td>
                                </tr>
                                <tr>

                                  <td class="border-right box-width-samll"> 20 </td>
                                  <td>
                                    <div class="input-box-with-dot">

                                      <input type="text" name="QueTwenty1" value={QueTwenty1} onChange={lineOneQue} class="form-control" />
                                      <input type="text" name="QueTwenty2" value={QueTwenty2} onChange={lineOneQue} class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="QueTwenty3" value={QueTwenty3} onChange={lineOneQue} class="form-control" />
                                   
                                    </div>
                                  </td>
                                </tr>
                                <tr>

                                  <td class="border-right box-width-samll"> 21 </td>
                                  <td>
                                    <div class="input-box-with-dot">
                                      <input type="text" name="QueTwentyOne1" value={QueTwentyOne1} onChange={lineOneQue} class="form-control" />
                                      <input type="text" name="QueTwentyOne2" value={QueTwentyOne2} onChange={lineOneQue} class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="QueTwentyOne3" value={QueTwentyOne3} onChange={lineOneQue} class="form-control" />
                                    </div>
                                  </td>
                                </tr>
                                <tr>

                                  <td class="border-right box-width-samll"> 22 </td>
                                  <td>
                                    <div class="input-box-with-dot">

                                      <input type="text" name="QueTwentyTwo1" value={QueTwentyTwo1} onChange={lineOneQue} class="form-control" />
                                      <input type="text" name="QueTwentyTwo2" value={QueTwentyTwo2} onChange={lineOneQue} class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="QueTwentyTwo3" value={QueTwentyTwo3} onChange={lineOneQue} class="form-control" />

                                    </div>
                                  </td>
                                </tr>


                              </tbody>
                            </table>
                          </div>
                        </div>

                      </div>


                      <div class="block-footer">
                        <h4> Teacher / Parent Check box </h4>
                        <div class="input-block-footer">
                          <input type="text" class="form-control" />
                        </div>
                      </div>


                    </div>


                    <div class="col-md-3 border-left">
                      <div class="border-col border-col-1">
                        <h4> To be Filed by the Reviewer </h4>
                        <div class="right-table-box">
                          <div class="left-col">
                            <h5> Bundle Number: </h5>
                            <div class="input-bundle-box">
                              <input type="text" class="form-control" />
                              <input type="text" class="form-control" />
                            </div>

                            <div class="left-col-second">
                              <h5> Packing Serial Number </h5>
                              <div class="input-bundle-box">
                                <input type="text" class="form-control" />
                                <input type="text" class="form-control" />
                              </div>
                            </div>

                            <div class="left-col-large-col-from">
                              <h5> Teacher Parent Signature:  </h5>
                              <div class="input-bundle-box-large">
                                <input type="text" class="form-control" />
                              </div>
                            </div>

                          </div>
                          <div class="right-col-table">
                            <table class="table">
                              <thead>
                                <tr>

                                  <th class="border-right box-width-samll">Q No.</th>
                                  <th>Marks</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>

                                <td class="border-right box-width-samll"> 1 </td>
                                  <td>
                                    <div class="input-box-with-dot">
                                      <input type="text" name="Que1One1" value={Que1One1} onChange={lineTwoQue} class="form-control" />
                                      <input type="text" name="Que1One2" value={Que1One2} onChange={lineTwoQue} class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="Que1One3" value={Que1One3} onChange={lineTwoQue} class="form-control" />
                                    </div>
                                  </td>
                                </tr>
                                <tr>

                                  <td class="border-right box-width-samll"> 2 </td>
                                  <td>
                                    <div class="input-box-with-dot">
                                      <input type="text" name="Que1Two1" value={Que1Two1} onChange={lineTwoQue} class="form-control" />
                                      <input type="text" name="Que1Two2" value={Que1Two2} onChange={lineTwoQue} class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="Que1Two3" value={Que1Two3} onChange={lineTwoQue} class="form-control" />
                                    </div>
                                  </td>
                                </tr>
                                <tr>

                                  <td class="border-right box-width-samll"> 3 </td>
                                  <td>
                                    <div class="input-box-with-dot">
                                      <input type="text" name="Que1Three1" value={Que1Three1} onChange={lineTwoQue} class="form-control" />
                                      <input type="text" name="Que1Three2" value={Que1Three2} onChange={lineTwoQue} class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="Que1Three3" value={Que1Three3} onChange={lineTwoQue} class="form-control" />
                                    </div>
                                  </td>
                                </tr>
                                <tr>

                                  <td class="border-right box-width-samll"> 4 </td>
                                  <td>
                                    <div class="input-box-with-dot">
                                    <input type="text" name="Que1Four1" value={Que1Four1} onChange={lineTwoQue} class="form-control" class="form-control" />
                                      <input type="text" name="Que1Four2" value={Que1Four2} onChange={lineTwoQue} class="form-control" class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="Que1Four3" value={Que1Four3} onChange={lineTwoQue} class="form-control" class="form-control" />
                                    </div>
                                  </td>
                                </tr>
                                <tr>

                                  <td class="border-right box-width-samll"> 5 </td>
                                  <td>
                                    <div class="input-box-with-dot">
                                      <input type="text" name="Que1Five1" value={Que1Five1} onChange={lineTwoQue} class="form-control" />
                                      <input type="text" name="Que1Five2" value={Que1Five2} onChange={lineTwoQue} class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="Que1Five3" value={Que1Five3} onChange={lineTwoQue} class="form-control" />
                                    </div>
                                  </td>
                                </tr>
                                <tr>

                                  <td class="border-right box-width-samll"> 6 </td>
                                  <td>
                                    <div class="input-box-with-dot">
                                      <input type="text" name="Que1Six1" value={Que1Six1} onChange={lineTwoQue} class="form-control" />
                                      <input type="text" name="Que1Six2" value={Que1Six2} onChange={lineTwoQue} class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="Que1Six3" value={Que1Six3} onChange={lineTwoQue} class="form-control" />
                                    </div>
                                  </td>
                                </tr>
                                <tr>

                                  <td class="border-right box-width-samll"> 7 </td>
                                  <td>
                                    <div class="input-box-with-dot">
                                      <input type="text" name="Que1Seven1" value={Que1Seven1} onChange={lineTwoQue} class="form-control" />
                                      <input type="text" name="Que1Seven2" value={Que1Seven2} onChange={lineTwoQue} class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="Que1Seven3" value={Que1Seven3} onChange={lineTwoQue} class="form-control" />
                                    </div>
                                  </td>
                                </tr>
                                <tr>

                                  <td class="border-right box-width-samll"> 8 </td>
                                  <td>
                                    <div class="input-box-with-dot">
                                      <input type="text" name="Que1Eight1" value={Que1Eight1} onChange={lineTwoQue} class="form-control" />
                                      <input type="text" name="Que1Eight2" value={Que1Eight2} onChange={lineTwoQue} class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="Que1Eight3" value={Que1Eight3} onChange={lineTwoQue}  class="form-control" />
                                    </div>
                                  </td>
                                </tr>
                                <tr>

                                  <td class="border-right box-width-samll"> 9 </td>
                                  <td>
                                    <div class="input-box-with-dot">

                                      <input type="text" name="Que1Nine1" value={Que1Nine1} onChange={lineTwoQue} class="form-control" />
                                      <input type="text" name="Que1Nine2" value={Que1Nine2} onChange={lineTwoQue} class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="Que1Nine3" value={Que1Nine3} onChange={lineTwoQue} class="form-control" />

                                    </div>
                                  </td>
                                </tr>
                                <tr>

                                  <td class="border-right box-width-samll"> 10 </td>
                                  <td>
                                    <div class="input-box-with-dot">
                                      <input type="text" name="Que1Ten1" value={Que1Ten1} onChange={lineTwoQue} class="form-control" />
                                      <input type="text" name="Que1Ten2" value={Que1Ten2} onChange={lineTwoQue} class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="Que1Ten3" value={Que1Ten3} onChange={lineTwoQue} class="form-control" />
                                    </div>
                                  </td>
                                </tr>
                                <tr>

                                <td class="border-right box-width-samll"> 11 </td>
                                  <td>
                                    <div class="input-box-with-dot">
                                      <input type="text" name="Que1Ele1" value={Que1Ele1} onChange={lineTwoQue} class="form-control" />
                                      <input type="text" name="Que1Ele2" value={Que1Ele2} onChange={lineTwoQue} class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="Que1Ele3" value={Que1Ele3} onChange={lineTwoQue} class="form-control" />
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td class="border-right box-width-samll"> 12 </td>
                                  <td>
                                    <div class="input-box-with-dot">
                                      <input type="text" name="Que1Twle1" value={Que1Twle1} onChange={lineTwoQue} class="form-control" />
                                      <input type="text" name="Que1Twle2" value={Que1Twle2} onChange={lineTwoQue} class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="Que1Twle3" value={Que1Twle3} onChange={lineTwoQue} class="form-control" />
                                    </div>
                                  </td>
                                </tr>
                                <tr>

                                  <td class="border-right box-width-samll"> 13 </td>
                                  <td>
                                    <div class="input-box-with-dot">
                                      <input type="text" name="Que1Thirt1" value={Que1Thirt1} onChange={lineTwoQue} class="form-control" />
                                      <input type="text" name="Que1Thirt2" value={Que1Thirt2} onChange={lineTwoQue} class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="Que1Thirt3" value={Que1Thirt3} onChange={lineTwoQue} class="form-control" />
                                    </div>
                                  </td>
                                </tr>
                                <tr>

                                  <td class="border-right box-width-samll"> 14 </td>
                                  <td>
                                    <div class="input-box-with-dot">
                                      <input type="text" name="Que1Forteen1" value={Que1Forteen1} onChange={lineTwoQue} class="form-control" />
                                      <input type="text" name="Que1Forteen2" value={Que1Forteen2} onChange={lineTwoQue} class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="Que1Forteen3" value={Que1Forteen3} onChange={lineTwoQue} class="form-control" />
                                    </div>
                                  </td>
                                </tr>
                                <tr>

                                  <td class="border-right box-width-samll"> 15 </td>
                                  <td>
                                    <div class="input-box-with-dot">
                                      <input type="text" name="Que1Fifteen1" value={Que1Fifteen1} onChange={lineTwoQue} class="form-control" />
                                      <input type="text" name="Que1Fifteen2" value={Que1Fifteen2} onChange={lineTwoQue} class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="Que1Fifteen3" value={Que1Fifteen3} onChange={lineTwoQue} class="form-control" />
                                    </div>
                                  </td>
                                </tr>
                                <tr>

                                  <td class="border-right box-width-samll"> 16 </td>
                                  <td>
                                    <div class="input-box-with-dot">
                                      <input type="text" name="Que1Sixteen1" value={Que1Sixteen1} onChange={lineTwoQue} class="form-control" />
                                      <input type="text" name="Que1Sixteen2" value={Que1Sixteen2} onChange={lineTwoQue} class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="Que1Sixteen3" value={Que1Sixteen3} onChange={lineTwoQue} class="form-control" />
                                    </div>
                                  </td>
                                </tr>
                                <tr>

                                  <td class="border-right box-width-samll"> 17 </td>
                                  <td>
                                    <div class="input-box-with-dot">
                                      <input type="text" name="Que1Seventeen1" value={Que1Seventeen1} onChange={lineTwoQue} class="form-control" />
                                      <input type="text" name="Que1Seventeen2" value={Que1Seventeen2} onChange={lineTwoQue} class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="Que1Seventeen3" value={Que1Seventeen3} onChange={lineTwoQue} class="form-control" />
                                    </div>
                                  </td>
                                </tr>
                                <tr>

                                  <td class="border-right box-width-samll"> 18 </td>
                                  <td>
                                    <div class="input-box-with-dot">
                                      <input type="text" name="Que1Eighteen1" value={Que1Eighteen1} onChange={lineTwoQue} class="form-control" />
                                      <input type="text" name="Que1Eighteen2" value={Que1Eighteen2} onChange={lineTwoQue} class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="Que1Eighteen3" value={Que1Eighteen3} onChange={lineTwoQue} class="form-control" />
                                    </div>
                                  </td>
                                </tr>
                                <tr>

                                  <td class="border-right box-width-samll"> 19 </td>
                                  <td>
                                    <div class="input-box-with-dot">
                                      
                                      <input type="text" name="Que1Ninteen1" value={Que1Ninteen1} onChange={lineTwoQue} class="form-control" />
                                      <input type="text" name="Que1Ninteen2" value={Que1Ninteen2} onChange={lineTwoQue} class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="Que1Ninteen3" value={Que1Ninteen3} onChange={lineTwoQue} class="form-control" />

                                    </div>
                                  </td>
                                </tr>
                                <tr>

                                  <td class="border-right box-width-samll"> 20 </td>
                                  <td>
                                    <div class="input-box-with-dot">

                                      <input type="text" name="Que1Twenty1" value={Que1Twenty1} onChange={lineTwoQue} class="form-control" />
                                      <input type="text" name="Que1Twenty2" value={Que1Twenty2} onChange={lineTwoQue} class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="Que1Twenty3" value={Que1Twenty3} onChange={lineTwoQue} class="form-control" />
                                   
                                    </div>
                                  </td>
                                </tr>
                                <tr>

                                  <td class="border-right box-width-samll"> 21 </td>
                                  <td>
                                    <div class="input-box-with-dot">
                                      <input type="text" name="Que1TwentyOne1" value={Que1TwentyOne1} onChange={lineTwoQue} class="form-control" />
                                      <input type="text" name="Que1TwentyOne2" value={Que1TwentyOne2} onChange={lineTwoQue} class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="Que1TwentyOne3" value={Que1TwentyOne3} onChange={lineTwoQue} class="form-control" />
                                    </div>
                                  </td>
                                </tr>
                                <tr>

                                  <td class="border-right box-width-samll"> 22 </td>
                                  <td>
                                    <div class="input-box-with-dot">

                                      <input type="text" name="Que1TwentyTwo1" value={Que1TwentyTwo1} onChange={lineTwoQue} class="form-control" />
                                      <input type="text" name="Que1TwentyTwo2" value={Que1TwentyTwo2} onChange={lineTwoQue} class="form-control" />
                                      <span class="dot-from">  </span>
                                      <input type="text" name="Que1TwentyTwo3" value={Que1TwentyTwo3} onChange={lineTwoQue} class="form-control" />

                                    </div>
                                  </td>
                                </tr>



                              </tbody>
                            </table>
                          </div>
                        </div>

                      </div>


                      <div class="block-footer">
                        <h4> Reviewer's check Box </h4>
                        <div class="input-block-footer">
                          <input type="text" class="form-control" />
                        </div>
                      </div>


                    </div>


                  </div>


                  <div class="row">
                    <div class="col-md-12 mt-3">
                      <div class="border-col border-col-footer">
                        <h4> Instruction to the supervisisng Teacher?parent </h4>

                        <div class="box-col-list">
                          <p> Please Verify the details filled in by the Cardidste with the information printed on the Cardidste's admission Card. </p>

                        </div>
                      </div>

                      <div class="footer-copy-right">
                        <p> The above respressnation does not purport to be the board answer sheet. </p>
                      </div>


                    </div>
                  </div>

                </div>
              </div>
            </div>
              <Button variant="contained"style={{color:"#9C35B2"}}onClick={UploadData} disableElevation disabled={buttonDisabled}>
                Submit Sheet Data
              </Button>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

Icons.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(iconsStyle)(Icons);
