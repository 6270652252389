import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';
import { createMuiTheme } from '@material-ui/core/styles';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

const theme = createMuiTheme({
    palette: {
      primary: {
        main: purple[500],
      },
      secondary: {
        main: '#f44336',
      },
    },
  });
  

export default function OutlinedTextBox(props) {
  const classes = useStyles();
  const { handleValue } = props
  const [val, setVal] = React.useState(props.value);

  const handleChange = (event) => {
    setVal(event.target.value)
    handleValue(event.target.value)
  }

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <Grid item xs={12} sm={6}>
          <MuiThemeProvider theme={theme} >
                <TextField
                onChange={handleChange}
                value={val}
                style={{width:'150px'}}
                color="primary"
                type={props.type}
                id="outlined-helperText"
                label={props.label}
                variant="outlined"
                />
            </MuiThemeProvider>
      </Grid>
    </form>
  );
}
