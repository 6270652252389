import React, { useState, useEffect } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Quote from "components/Typography/Quote.jsx";
import Muted from "components/Typography/Muted.jsx";
import Primary from "components/Typography/Primary.jsx";
import Info from "components/Typography/Info.jsx";
import Success from "components/Typography/Success.jsx";
import Warning from "components/Typography/Warning.jsx";
import Danger from "components/Typography/Danger.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { Grid } from '@material-ui/core';
import ZoomImage from '../../Utils/ZoomIn';

import NumberTextBox from '../../Utils/TestField';
import Form from '../../Utils/Form';
import ICSEForm from "../../views/Icons/Icons";
const style = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative"
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px"
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};
function TypographyPage(props) {
  const { classes } = props;
  const [uniqueId, setUniqueId] = useState();
  const [indexValue, setIndexValue] = useState();
  const [examinarName, setExaminarName] = useState();
  const [studentName, setStudentName] = useState();

  console.log(props);
  var url = props.location.state ? props.location.state.url : ''

  const handleValue = value => {
    console.log(value);
    setUniqueId(value);
  }
  const handleValueIndexNum = value => {
    console.log(value);
    setIndexValue(value);
  }

  const handleValueExaminar = value => {
    console.log(value);
    setExaminarName(value);
  }
  const handleValueStudent = value => {
    console.log(value);
    setStudentName(value);
  }
  return (
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>OMCS Sheet Data Review</h4>
        <p className={classes.cardCategoryWhite}>
          Review And Upload Your Sheet Data
        </p>
      </CardHeader>
      <CardBody>
        <Grid container>
          <Grid item xs={12}>
            {
              url !== '' ? <ZoomImage urlImage ={url}></ZoomImage> : null
            }
             {/* <img src={url} style={{ maxWidth: '99%', height: 'auto', border: '3px solid #A23DB7' }} /> : "Sheet Not Found" */}
          </Grid>
          {/* <Grid item xs={1}>
            <div class="vl" style={{borderLeft: '6px solid black' ,height: '700px', borderWidth: 'thin'}}></div>
          
          </Grid> */}
           <Grid item xs={7}>

           </Grid>
          <Grid item xs={12}>
            <p>Please Correct If Any Wrong Details Filed </p>
            {/* <Form formValues = {props.location.state}></Form> */}
            <ICSEForm formValues = {props.location.state}></ICSEForm>
          </Grid>
          {/* <Grid item xs={12}>
            <Grid item xs={3}>
              <NumberTextBox
                handleValue={handleValue}
                type="number"
                label="UniqueID"
              >
              </NumberTextBox>
            </Grid>
            <Grid item xs={3}>
              <NumberTextBox
                handleValue={handleValueIndexNum}
                type="number"
                label="Index No"
              >
              </NumberTextBox>
            </Grid>

            <Grid item xs={3}>
              <NumberTextBox
                handleValue={handleValueExaminar}
                type="text"
                label="Examinar Name"
              >
              </NumberTextBox>
            </Grid>
            <Grid item xs={3}>
              <NumberTextBox
                handleValue={handleValueStudent}
                type="text"
                label="Student Name"
              >
              </NumberTextBox>
            </Grid>
          </Grid> */}
        </Grid>
      </CardBody>
    </Card>
  );
}

export default withStyles(style)(TypographyPage);
