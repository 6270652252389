import React, { useState, useEffect } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Quote from "components/Typography/Quote.jsx";
import Muted from "components/Typography/Muted.jsx";
import Primary from "components/Typography/Primary.jsx";
import Info from "components/Typography/Info.jsx";
import Success from "components/Typography/Success.jsx";
import Warning from "components/Typography/Warning.jsx";
import Danger from "components/Typography/Danger.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import NumberTextBox from './TestField';

const style = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative"
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px"
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};
function Form(props) {
  const { classes } = props;
  const [uniqueId, setUniqueId] = useState(props.formValues.ocr.formData['Unique ID']);
  const [indexValue, setIndexValue] = useState(props.formValues.ocr.formData['Index No']);
  const [examinarName, setExaminarName] = useState(props.formValues.ocr.formData['Initials of the Supervising Examiner']);
  const [studentName, setStudentName] = useState(props.formValues.ocr.formData['Signature of the Candidate']);

  console.log(props);
  console.log(props.formValues.ocr.formData['Unique ID'])
  //var url = props.location.state ? props.location.state.url : ''

  const handleValue = value => {
    console.log(value);
    setUniqueId(value);
  }
  const handleValueIndexNum = value => {
    console.log(value);
    setIndexValue(value);
  }

  const handleValueExaminar = value => {
    console.log(value);
    setExaminarName(value);
  }
  const handleValueStudent = value => {
    console.log(value);
    setStudentName(value);
  }

  const submit = () => {
    var data = {
        uniqueId : uniqueId,
        indexNo : indexValue,
        examinarName : examinarName,
        studentName : studentName
    }
    if(uniqueId==='' || indexValue==='' || examinarName==='' || studentName==='' ){
        alert("field should not be empty")
    }else{
        alert("good");
    }
  }

  return (
    <Card>
      <CardBody >
        <Grid container>
            <Grid item xs={12} sm={6}>
              <NumberTextBox
                handleValue={handleValue}
                type="text"
                label="UniqueID"
                value={uniqueId}
              >
              </NumberTextBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <NumberTextBox
                handleValue={handleValueIndexNum}
                type="text"
                label="Index No"
                value={indexValue}
              >
              </NumberTextBox>
            </Grid>

            <Grid item xs={12} sm={6}>
              <NumberTextBox
                handleValue={handleValueExaminar}
                type="text"
                label="Examinar Name"
                value={examinarName}
              >
              </NumberTextBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <NumberTextBox
                handleValue={handleValueStudent}
                type="text"
                label="Student Name"
                value={studentName}
              >
              </NumberTextBox>
            </Grid>
            <Grid item xs={12} >
                <Button variant="contained" color="primary" style={{width:'100%'}} onClick={submit} disableElevation>
                    Submit
                </Button>
            </Grid>
            
        </Grid>
      </CardBody>
    </Card>
  );
}

export default withStyles(style)(Form);
