/* eslint-disable */
import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import FileBase64 from 'react-file-base64';
import {Form,FormGroup,Label,FormText,Input} from "reactstrap";
import CircularProgress from '@material-ui/core/CircularProgress';
import '../../Utils/Upload.css';

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tl: false,
      tc: false,
      tr: false,
      bl: false,
      bc: false,
      br: false,
      confirmation : "",
      isLoading : "",
      files : "",
      Invoice : "",
      Amount : "",
      InvoiceDate: "",
      Vendor : "",
      Description : "",
      val:{}
    };
    this.val={}
    this.data={}
    this.handleChane= this.handleChane.bind(this);
  }
  // to stop the warning of calling setState of unmounted component
  componentWillUnmount() {
    var id = window.setTimeout(null, 0);
    while (id--) {
      window.clearTimeout(id);
    }
  }

  handleChane(event){
    event.preventDefault();
    const target = event.target;
    const value=target.value;
    const name=target.name;
    this.setState({name:value});
  }

  async handleSubmit(event){
    event.preventDefaulr();
    this.setState({confirmation : "Uploading..."});
  }

  async getFiles(files){
    this.setState({
    isLoading : "Extracting data",
    files : files
    });
    const UID= Math.round(1+ Math.random() * (1000000-1));

    var date={
      fileExt:"jpg",
      imageID: UID,
      folder:UID,
      img : this.state.files[0].base64
    };

    this.setState({confirmation : "Uploading..."})
    await fetch(
        'https://icse-demo.digiclave.in/image-upload',
        {
        method: "POST",
        body : JSON.stringify(date)
        }
    ).then(response=>response.text()).then(result=>{
      var d = JSON.parse(result);   
      console.log(d); 
      this.data.url = d.value.Location
    })


    this.setState({confirmation : "Processing..."})
    let targetImage= {key:UID + ".jpg"};
    const response=await fetch(
        'https://icse-demo.digiclave.in/ocr',
        {
        method: "POST",
        body : JSON.stringify(targetImage)
        }
    ).then(response => response.text())
    .then(result => {console.log(result); this.val = JSON.parse(result); this.data.ocr = this.val;
    this.forceUpdate()})
    .catch(error => console.log('error', error));

    // this.setState({val :response })
    // console.log(response)
    console.log(this.data)
    this.setState({confirmation : "Done"})

    //const { history } = this.props;
    return this.props.history.push({
      pathname: '/admin/omcsreview',
      state: this.data 
    })
    //return history.push("/admin/table");

    // const OCRBody = await response.json();
    // console.log("OCRBody",OCRBody);

    // this.setState({Amount :OCRBody.body[0] })
    // this.setState({Invoice :OCRBody.body[1] })
    // this.setState({InvoiceDate :OCRBody.body[2] })

  }


  showNotification(place) {
    var x = [];
    x[place] = true;
    this.setState(x);
    this.alertTimeout = setTimeout(
      function() {
        x[place] = false;
        this.setState(x);
      }.bind(this),
      6000
    );
  }
  render() {
    const { classes } = this.props;
    const processing=this.state.confirmation;
    return (
      
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>OCR</h4>
          <p className={classes.cardCategoryWhite}>
            Upload Your Sheet
          </p>
        </CardHeader>
        <CardBody>
          {/* <GridContainer> */}
          <div className="row">
               <div className="col-6 offset-3">
                  
                    <Form onSubmit={this.handleSubmit} >
                        <FormGroup>
                           <h3 style={{color:"#9C35B2"}} >{processing}</h3>    
                           {processing !=="" ? <CircularProgress style={{color:"#9C35B2"}} />:null}
                           <h6 style={{color:"#0693E3"}}>Upload Marksheet</h6>
                           <FormText color="muted">JPG</FormText>
                       
                       
                        <div className="form-group files color">
                            <FileBase64 
                            multiple={true} 
                            onDone={this.getFiles.bind(this)}></FileBase64>

                        </div>
                        </FormGroup>  
                        <Label>
                            <h6 style={{color:"#008b02"}}>After Processing You Will Be Redirected on Review Page</h6>
                        </Label>
                        {/* <p>{JSON.stringify(this.val)}</p> */}
                        {/* <FormGroup>
                            <Label>
                                <h6>Invoice</h6>
                            </Label>
                            <Input 
                                type="text"
                                name="Invoice"
                                id="Invoice"
                                required
                                value={this.state.Invoice}
                                onChange={this.handleChane}
                            />

                        </FormGroup>


                        <FormGroup>
                            <Label>
                                <h6>Amount ($)</h6>
                            </Label>
                            <Input 
                                type="text"
                                name="Amount"
                                id="Amount"
                                required
                                value={this.state.Amount}
                                onChange={this.handleChane}
                            />
                        </FormGroup>



                        <FormGroup>
                            <Label>
                                <h6>Date</h6>
                            </Label>
                            <Input 
                                type="text"
                                name="InvoiceDate"
                                id="InvoiceDate"
                                required
                                value={this.state.InvoiceDate}
                                onChange={this.handleChane}
                            />
                        </FormGroup>


                        <FormGroup>
                            <Label>
                                <h6>Vendor</h6>
                            </Label>
                            <Input 
                                type="text"
                                name="Vendor"
                                id="Vendor"
                                required
                                value={this.state.Vendor}
                                onChange={this.handleChane}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label>
                                <h6>Description</h6>
                            </Label>
                            <Input 
                                type="text"
                                name="Description"
                                id="Description"
                                required
                                value={this.state.Description}
                                onChange={this.handleChane}
                            />
                        </FormGroup>
                        <Button className="btn btn-lg btn-block  btn-success">
                            Submit
                        </Button> */}
                    </Form>   
                </div>  
           </div>
          {/* </GridContainer> */}
        </CardBody>
      </Card>
    );
  }
}

export default withStyles(styles)(Notifications);
