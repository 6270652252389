import React from "react";
import PropTypes from "prop-types";
import axios from "axios";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import CircularProgress from '@material-ui/core/CircularProgress';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import loginPageStyle from "assets/jss/material-dashboard-react/views/loginPageStyle.jsx";

import Urls from '../../Utils/Urls';

const { REACT_APP_SERVER_URL } = process.env;

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      isLoading:false,
      errors: {}
    };
  }
  login = async e => {
    e.preventDefault();

    this.setState({isLoading:true});

    const { history } = this.props;

    const fields = ["username", "pass"];
    const formElements = e.target.elements;

    console.log(formElements.namedItem('username').value);
    console.log(formElements.namedItem('pass').value);

    const formValues = {
      username: formElements.namedItem('username').value,
      pass: formElements.namedItem('pass').value
    }

    var config = {
      method: 'post',
      url: `${Urls.baseUrl}/login-user`,
      // headers: { 
      //   "Access-Control-Allow-Origin": '*',
      //   "Content-Type": 'application/json'
      // },
      mode: 'cors',
      // cache: 'no-cache',
      data : JSON.stringify(formValues)
    };

    let loginRequest;

    try {
      loginRequest = await axios(
        config
      );
    } catch ({ response }) {
      loginRequest = response;
      this.setState({isLoading:false});
    }
    
    console.log(loginRequest);
    if (loginRequest.data.body === "Success") {
      toast.success(`Loged In Successfully`)
      localStorage.setItem('tokenid', loginRequest.data.value.idToken.jwtToken);
      localStorage.setItem('refreshToken', loginRequest.data.value.refreshToken.token);
      localStorage.setItem('accessToken', loginRequest.data.value.accessToken.jwtToken);
      localStorage.setItem('exp', loginRequest.data.value.accessToken.payload.exp);
      localStorage.setItem('username', loginRequest.data.value.accessToken.payload.username);
      localStorage.setItem('loginTime', loginRequest.data.value.accessToken.payload.auth_time);     
      setTimeout(function(){ return history.push("/dashboard"); }, 2000);
      
    }else{
      console.log("in else")
      this.setState({isLoading:false});
      var err = {
        invalidEmailOrPassword: loginRequest.data.body
      }
  
      this.setState({
        errors: err
      });

    }

   
  };
  handleToggle = value => {
    const checked = this.state.checked;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked
    });
  };
  render() {
    const { classes } = this.props;
    const { errors } = this.state;
    return (
      <div className={classes.container}>
       
        <GridContainer justify="center">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover>
        </ToastContainer>
          <GridItem xs={12} sm={8}>
            <h4 className={classes.textCenter} style={{ marginTop: 0 }}>
              Log in to ICSE Admin Portal{" "}
            </h4>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form onSubmit={this.login}>
              <Card className={classes[this.state.cardAnimaton]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="primary"
                >
                  <h4 className={classes.cardTitle}>Log in</h4>
                  <div className={classes.socialLine}>
                    {
                      this.state.isLoading ? <CircularProgress style={{color:"white"}} /> : null
                    }
                  
                    {/* {[
                      "fa fa-facebook-square",
                      "fa fa-twitter",
                      "fa fa-google-plus"
                    ].map((prop, key) => {
                      return (
                        <Button
                          color="transparent"
                          justIcon
                          key={key}
                          className={classes.customButtonClass}
                        >
                          <i className={prop} />
                        </Button>
                      );
                    })} */}
                  </div>
                </CardHeader>
                <CardBody>
                  <p
                    className={`${classes.textCenter} ${classes.checkboxLabel}`}
                  >
                   
                  </p>
                  <CustomInput
                    labelText="Email..."
                    id="email"
                    error={errors.username || errors.invalidEmailOrPassword}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.formControlClassName
                    }}
                    inputProps={{
                      required: true,
                      name: "username",
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      )
                    }}
                  />
                  <CustomInput
                    labelText="Password"
                    id="pass"
                    error={errors.password || errors.invalidEmailOrPassword}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.formControlClassName
                    }}
                    inputProps={{
                      type: "password",
                      required: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      )
                    }}
                  />
                  <FormControlLabel
                    classes={{
                      root:
                        classes.checkboxLabelControl +
                        " " +
                        classes.checkboxLabelControlClassName,
                      label: classes.checkboxLabel
                    }}
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle(1)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    label={<span>Remember me</span>}
                  />
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <Button type="submit" color="primary" simple size="lg" block disabled={this.state.isLoading}>
                    Let's Go
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  errors: PropTypes.object
};

export default withStyles(loginPageStyle)(LoginPage);
