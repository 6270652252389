import React, { Component } from "react";
import Button from '@material-ui/core/Button';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

class ZoomIn extends Component {
    constructor(props) {
        super(props);
    }
  render() {
      console.log(this.props)
    return (
      <TransformWrapper
        defaultScale={1}
        defaultPositionX={100}
        defaultPositionY={50}
      >
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <React.Fragment>
            <div className="tools" style={{paddingBottom:'10px'}}>
              <Button onClick={zoomIn} disableElevation><ZoomInIcon></ZoomInIcon></Button>
              <Button onClick={zoomOut} disableElevation><ZoomOutIcon></ZoomOutIcon></Button>
              <Button onClick={resetTransform} disableElevation><ZoomOutMapIcon></ZoomOutMapIcon></Button>
            </div>
            <TransformComponent >
              <img src={this.props.urlImage} alt="test" style={{ maxWidth: '99%', height: 'auto', border: '3px solid #A23DB7' }} />
              <div><strong>{this.props.urlImage.split('amazonaws.com/')[1]}</strong></div>
            </TransformComponent>
          </React.Fragment>
        )}
      </TransformWrapper>
    );
  }
}

export default ZoomIn;